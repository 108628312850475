import React from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import Card from 'components/Card';
import Button from 'components/Button';
import InputMask from 'components/InputMask';
import styles from '../styles';

const Margem = ({ onSubmit, loading, disableActions, documentoItem = {} }) => {
  const defaultValues = {
    documento_item_id: documentoItem?.DocumentoItemMc?.id,
    custo: documentoItem?.DocumentoItemMc?.custo || '',
    internacional: documentoItem?.DocumentoItemMc?.internacional || '',
    fretend: documentoItem?.DocumentoItemMc?.fretend || '',
    segurond: documentoItem?.DocumentoItemMc?.segurond || '',
    impfednd: documentoItem?.DocumentoItemMc?.impfednd || '',
    comissaond: documentoItem?.DocumentoItemMc?.comissaond || '',
    medio: documentoItem?.DocumentoItemMc?.medio || '',
    medioc: documentoItem?.DocumentoItemMc?.medioc || '',
    custocompra: documentoItem?.DocumentoItemMc?.custocompra || '',
    custocomprac: documentoItem?.DocumentoItemMc?.custocomprac || '',
  };
  const { control, handleSubmit } = useForm({ defaultValues });

  const onConfirm = (Margem) => {
    const data = {
      Itens: [
        {
          id: documentoItem?.id,
          documento_id: documentoItem?.documento_id,
          Margem,
        },
      ],
    };
    onSubmit(data);
  };

  return (
    <Card title="Margem de Contribuição" style={styles?.card}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputMask name="custo" control={control} label="Custo" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="internacional"
            control={control}
            label="Internacionalização"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="fretend" control={control} label="Frete (ND)" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="segurond" control={control} label="Seguro (ND)" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask
            name="impfed"
            control={control}
            label="Impostos Federais (ND)"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask
            name="comissaond"
            control={control}
            label="Comissão (ND)"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="medio"
            control={control}
            label="Custo Médio"
            disabled={
              documentoItem?.Documento?.NaturezaOperacao?.operacao !== 'ENTRADA'
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="medioc"
            control={control}
            label="Custo Médio Contábil"
            disabled={
              documentoItem?.Documento?.NaturezaOperacao?.operacao !== 'ENTRADA'
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="custocompra"
            control={control}
            label="Custo de Compra"
            disabled={
              documentoItem?.Documento?.NaturezaOperacao?.operacao !== 'ENTRADA'
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="custocomprac"
            control={control}
            label="Custo de Compra Contábil"
            disabled={
              documentoItem?.Documento?.NaturezaOperacao?.operacao !== 'ENTRADA'
            }
          />
        </Grid>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              loading={loading}
              onClick={handleSubmit(onConfirm)}
            >
              Salvar
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Margem;
