import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import {
  AllInbox,
  Delete,
  Edit,
  QrCode,
  QueryStats,
  Visibility,
} from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { checkInfosProduto, summarizer } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { RemessasContext } from 'contexts/RemessasContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog } from 'components/Modals';
import TableContainer from 'components/TableContainer';
import VirtualDrop from 'components/VirtualDrop';
import ButtonMenu from 'components/ButtonMenu';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import Blank from 'assets/blank.png';
import ImportModal from './Modals/ImportModal';
import ItemModal from './Modals/ItemModal';
import KitModal from './Modals/KitModal';
import styles from './styles';
import moment from 'moment';
import api from 'services/api';

const Gerar = () => {
  let timer;
  const waitTime = 1000;
  const navigate = useNavigate();
  const { especie_id, natureza_operacao_id, itens = [] } = useParams();
  const { user } = useContext(AuthContext);
  const defaultValues = {
    especie_id: null,
    natureza_operacao_id: null,
    filial_id: user?.filial_id,
    cadastro_id: null,
    observacao: '',
    //ITENS
    produto_id: null,
    cdbarra: '',
    descricao: '',
    quantidade: '',
    unidade_medida_id: null,
    referencia: '',
    desconto1_pc: '',
    desconto_vlr: '',
    preco: '',
    infadc: '',
    itens: [],
    kits: [],
  };
  const [additionals, setAdditionals] = useState(null);
  const [useBarCode, setUserBarCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const { openDialog, closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { postRemessa, postLoading } = useContext(RemessasContext);
  const { control, handleSubmit, resetField, watch, setValue } = useForm({
    defaultValues,
  });

  const loadData = async () => {
    try {
      const values = { itens: JSON.parse(itens), size: 1000 };
      setLoading(true);
      const { data } = await api.post(`/Comercial/Remessas/Estrutura`, values);
      const arr = (data?.rows || [])?.map((s) => ({
        subtotal: parseFloat(
          ((s?.preco || 0) * (s?.quantidade || 0))?.toFixed(2)
        ),
        produto_id: s?.componente_id,
        descricao: s?.componente,
        quantidade: s?.quantidade,
        unidade_medida_id: s?.unidade_medida_id,
        referencia: s?.referencia,
        preco: s?.preco,
      }));
      setValue('itens', [...watch('itens'), ...arr]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (especie_id && natureza_operacao_id) {
      setValue('especie_id', especie_id);
      setValue('natureza_operacao_id', natureza_operacao_id);
      if (Boolean(JSON.parse(itens?.length))) {
        loadData();
      }
    } else {
      toastWarning('Parâmetro inválido');
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    const desconto_vlr =
      watch('desconto1_pc') * watch('preco') * watch('quantidade');
    setValue(
      'desconto_vlr',
      desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr
    );
  }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

  useEffect(() => {
    if (Boolean(watch('produto_id'))) {
      getAdditionals({
        produto_id: watch('produto_id'),
        submit: Boolean(watch('cdbarra')),
      });
    }
  }, [watch('produto_id')]);

  const subtotal =
    (watch('quantidade') || 0) * (watch('preco') || 0) +
    (watch('desconto_vlr') || 0);

  const getTotalDocumento = () => {
    let total = summarizer(watch('itens'), 'subtotal') || 0;
    watch('kits')?.map((k) => {
      k?.itens?.map((ki) => {
        total +=
          (k?.quantidade || 0) * (ki?.preco || 0) * (ki?.quantidade || 0);
      });
    });
    return total;
  };

  const getAdditionals = async ({ produto_id, submit }) => {
    setLoading(true);
    const produto = await checkInfosProduto({
      produto_id,
      disponivel: 'SIM',
      filial_id: watch('filial_id'),
      vendedor_id: watch('cadastro_id'),
      cadastro_id: watch('cadastro_id'),
      condicao_pagamento_id: watch('condicao_pagamento_id'),
    });
    setAdditionals(produto);
    setValue('descricao', produto?.descricao);
    setValue('desconto1_pc', produto?.desconto1_pc);
    if (!Boolean(watch('quantidade'))) {
      setValue('quantidade', produto?.volume || 1);
    }
    setValue('unidade_medida_id', produto?.unidade_medida_id);
    setValue('referencia', produto?.referencia);
    setValue('preco', produto?.preco);
    if (submit) {
      handleSubmit(onAddItem)();
    }
    setLoading(false);
  };

  const onAddItem = (values) => {
    const subtotal =
      (values?.quantidade || 0) * (values?.preco || 0) +
      (values?.desconto_vlr || 0);
    setValue('itens', [
      {
        subtotal,
        produto_id: values?.produto_id,
        descricao: values?.descricao,
        quantidade: values?.quantidade,
        unidade_medida_id: values?.unidade_medida_id,
        referencia: values?.referencia,
        desconto1_pc: values?.desconto1_pc,
        desconto_vlr: values?.desconto_vlr,
        preco: values?.preco,
        infadc: values?.infadc,
      },
      ...values?.itens,
    ]);
    setAdditionals(null);
    resetField('cdbarra');
    resetField('produto_id');
    resetField('descricao');
    resetField('quantidade');
    resetField('unidade_medida_id');
    resetField('referencia');
    resetField('desconto1_pc');
    resetField('desconto_vlr');
    resetField('preco');
    resetField('infadc');
  };

  const onAddKit = (values) => {
    setValue('kits', [...watch('kits'), values]);
    setTab(1);
  };

  const onEditItem = ({ values, index }) => {
    const itens = watch('itens');
    const item = itens[index];
    if (values?.quantidade > item?.quantidade_max) {
      return toastWarning(
        `Quantidade limite excedida. (Limite: ${item?.quantidade_max})`
      );
    }
    if (Boolean(item?.preco_max) && values?.preco > item?.preco_max) {
      return toastWarning(
        `Preço limite excedido. (Limite: ${item?.preco_max})`
      );
    }

    const subtotal =
      (values?.quantidade || 0) * (values?.preco || 0) +
      (values?.desconto_vlr || 0);
    setValue(
      'itens',
      itens?.map((itm, i) =>
        index === i ? { ...itm, ...values, subtotal } : itm
      )
    );
    closeDialog();
  };

  const onEditKit = ({ values, index }) => {
    const kits = watch('kits');
    kits[index] = values;
    setValue('kits', kits);
  };

  const onDeleteItem = (index) => {
    setValue(
      'itens',
      watch('itens')?.filter((_, i) => i !== index)
    );
  };

  const onDeleteKit = (index) => {
    setValue(
      'kits',
      watch('kits')?.filter((_, i) => i !== index)
    );
  };

  const onSubmit = (values) => {
    let data = {
      Documento: {
        especie_id: values?.especie_id,
        natureza_operacao_id: values?.natureza_operacao_id,
        users_id: user?.id,
        filial_id: values?.filial_id,
        cadastro_id: values?.cadastro_id,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
        observacao: values?.observacao,
      },
      Kits: values?.kits,
      Itens: values?.itens,
    };
    if ([19, 62]?.includes(values?.natureza_operacao_id)) {
      data = {
        ...data,
        Entrada: { dtentrada: moment().format('YYYY-MM-DD HH:mm:ss') },
      };
    } else {
      data = { ...data, Saida: { vendedor_id: values?.cadastro_id } };
    }
    postRemessa({ data, cb: () => navigate(-1) });
  };

  const options = [
    {
      name: 'Adicionar Kit',
      icon: 'add',
      action: () =>
        openDialog(
          <KitModal vendedor_id={watch('cadastro_id')} onSubmit={onAddKit} />,
          'Adicionar Kit'
        ),
    },
    {
      name: 'Importar Itens',
      icon: 'upload',
      show: [19, 22]?.includes(Number(natureza_operacao_id)),
      action: () => {
        openDialog(
          <ImportModal
            onSubmit={(arr) => setValue('itens', [...arr, ...itens])}
          />,
          'Importar Itens'
        );
      },
    },
  ];

  return (
    <Container>
      <Header titulo="Cadastrar Remessa" />
      <Card title="Informações Gerais">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={10}>
            <VirtualDrop
              name="cadastro_id"
              control={control}
              label="Entidade"
              options={drops?.Entidade}
              refresh="Entidade"
            />
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <Visibility fontSize="small" />
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Indicadores/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <QueryStats fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined' }}
              options={options}
            />
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  {Boolean(useBarCode) ? (
                    <Input
                      name="cdbarra"
                      control={control}
                      label="Código de Barras"
                      onKeyUp={(e) => {
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                          const value = e?.target?.value;
                          if (Boolean(value)) {
                            const produto = drops?.Produto.find(
                              (f) => f?.cdbarra == e?.target?.value
                            );
                            setValue('produto_id', produto?.value || null);
                          }
                        }, waitTime);
                      }}
                    />
                  ) : (
                    <VirtualDrop
                      name="produto_id"
                      control={control}
                      label="Produto"
                      options={drops?.Produto}
                      refresh="Produto"
                    />
                  )}
                </Grid>
                <Grid item xs={2} textAlign="center">
                  <IconButton
                    size="small"
                    onClick={() => setUserBarCode(!useBarCode)}
                  >
                    {Boolean(useBarCode) ? (
                      <AllInbox color="primary" />
                    ) : (
                      <QrCode color="primary" />
                    )}
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Input name="descricao" control={control} label="Descrição" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="quantidade"
                    control={control}
                    label="Quantidade"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Dropdown
                    name="unidade_medida_id"
                    control={control}
                    label="Unidade de Medida"
                    options={drops?.UnidadeMedida}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Input
                    name="referencia"
                    control={control}
                    label="Referência"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    name="desconto1_pc"
                    control={control}
                    label="% Desconto"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    name="preco"
                    control={control}
                    label="Preço"
                    disabled={user?.permissoes?.block_altera_precos}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    name="infadc"
                    control={control}
                    label="Informações Adicionais"
                  />
                </Grid>
                <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                  <Box
                    component="img"
                    sx={styles?.image}
                    src={additionals?.imagem || Blank}
                  />
                </Grid>
                {Boolean(loading) ? (
                  <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                    <Loader />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} {...styles?.textDetailsProps}>
                    <Typography variant="h6" color="primary">
                      {additionals?.descricao}
                    </Typography>
                    <Typography variant="body1">
                      Referência: {additionals?.referencia || '-'}
                    </Typography>
                    <Typography variant="body1">
                      Saldo: {additionals?.saldo || '0'}{' '}
                      {additionals?.UnidadeMedida?.descricao || ''}
                    </Typography>
                    <Typography variant="body1">
                      Localização: {additionals?.localizador || '-'}
                    </Typography>
                    <Typography variant="h6" align="right" color="primary">
                      {subtotal?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={handleSubmit(onAddItem)}
                  >
                    ADICIONAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Tabs
                    value={tab}
                    onChange={(_, v) => setTab(v)}
                    variant="fullWidth"
                  >
                    <Tab label="Itens" />
                    <Tab label="Kits" />
                  </Tabs>
                </Grid>
                {tab === 0 && (
                  <Grid item xs={12}>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Referência</TableCell>
                            <TableCell>Descrição</TableCell>
                            <TableCell>Quantidade</TableCell>
                            <TableCell>Desconto</TableCell>
                            <TableCell>Preço Unitário</TableCell>
                            <TableCell>Sub Total</TableCell>
                            <TableCell align="center">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {watch('itens')?.map((item, index) => (
                            <TableRow key={index?.toString()}>
                              <TableCell>{item?.referencia}</TableCell>
                              <TableCell>{item?.descricao}</TableCell>
                              <TableCell>{item?.quantidade}</TableCell>
                              <TableCell>
                                {item?.desconto_vlr?.toLocaleString('pt-br', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                              </TableCell>
                              <TableCell>
                                {item?.preco?.toLocaleString('pt-br', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                              </TableCell>
                              <TableCell>
                                {item?.subtotal?.toLocaleString('pt-br', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    openDialog(
                                      <ItemModal
                                        item={item}
                                        onSubmit={(values) =>
                                          onEditItem({ values, index })
                                        }
                                      />,
                                      'Editar Item'
                                    )
                                  }
                                >
                                  <Edit />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => onDeleteItem(index)}
                                >
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
                {tab === 1 && (
                  <Grid item xs={12}>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Produtos</TableCell>
                            <TableCell>Quantidade</TableCell>
                            <TableCell align="center">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {watch('kits')?.map((item, index) => (
                            <TableRow key={index?.toString()}>
                              <TableCell>
                                {item?.itens?.map((m, i) => (
                                  <Typography key={i?.toString()}>
                                    {m?.quantidade || 0}x {m?.descricao}
                                  </Typography>
                                ))}
                              </TableCell>
                              <TableCell>{item?.quantidade}</TableCell>
                              <TableCell align="center">
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    openDialog(
                                      <KitModal
                                        vendedor_id={watch('cadastro_id')}
                                        item={item}
                                        onSubmit={(values) =>
                                          onEditKit({ values, index })
                                        }
                                      />,
                                      'Editar Kit'
                                    )
                                  }
                                >
                                  <Edit />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => onDeleteKit(index)}
                                >
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {getTotalDocumento()?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Grid item xs={12} textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Container>
  );
};

export default Gerar;
