import React from 'react';
import { Grid } from '@mui/material';
import Input from 'components/Input';

const Adicionais = ({ tab, control }) => {
  const display = tab === 3 ? 'flex' : 'none';

  return (
    <Grid container spacing={2} sx={{ display }}>
      <Grid item xs={12}>
        <Input
          name="dadosadc"
          control={control}
          label="Dados Adicionais"
          multiline
          rows={5}
        />
      </Grid>
    </Grid>
  );
};

export default Adicionais;
