import React, { useState } from 'react';
import {
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Typography,
} from '@mui/material';
import Button from 'components/Button';
import { useModal } from 'components/Modals';

const ImpExpModal = ({ onSubmit, selected }) => {
  const { closeModal } = useModal();
  const [value, setValue] = useState(null);

  const onConfirm = () => {
    if (Boolean(value)) {
      onSubmit({ value, selected });
    }
    closeModal();
  };

  const options = [
    {
      key: 1,
      value: 1,
      label: 'DANFE Simplificada',
      type: 'IMP',
    },
    {
      key: 2,
      value: 2,
      label: 'Etiquetas Destinatário',
      type: 'IMP',
    },
    {
      key: 3,
      value: 3,
      label: 'XML(s) Selecionados',
      type: 'EXP',
    },
    {
      key: 4,
      value: 4,
      label: 'XML(s) Selecionados + DANFE',
      type: 'EXP',
    },
    {
      key: 5,
      value: 5,
      label: 'XML(s) Filtrados',
      type: 'EXP',
    },
    {
      key: 6,
      value: 6,
      label: 'XML(s) Filtrados + DANFE',
      type: 'EXP',
    },
    {
      key: 7,
      value: 7,
      label: 'XML(s) Inutilizados',
      type: 'EXP',
    },
  ];

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6}>
            <Typography variant="h6" sx={{ p: '1rem' }}>
              Exportar
            </Typography>
            <FormControl sx={{ p: '1em' }}>
              <RadioGroup
                value={value}
                onChange={(e) => setValue(e?.target?.value)}
              >
                {options?.map(
                  ({ type, ...rest }) =>
                    type === 'EXP' && (
                      <FormControlLabel {...rest} control={<Radio />} />
                    )
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6}>
            <Typography variant="h6" sx={{ p: '1rem' }}>
              Imprimir
            </Typography>
            <FormControl sx={{ p: '1em' }}>
              <RadioGroup
                value={value}
                onChange={(e) => setValue(e?.target?.value)}
              >
                {options?.map(
                  ({ type, ...rest }) =>
                    type === 'IMP' && (
                      <FormControlLabel {...rest} control={<Radio />} />
                    )
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm}>Confirmar</Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default ImpExpModal;
