import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import { findOnArray } from 'utils/functions';
import { useDialog } from 'components/Modals';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';

const PagamentoModal = ({ item, index, form }) => {
  const defaultValues = {
    dtvenc: item?.dtvenc || null,
    valor: item?.valor || '',
    forma_pagto_id:
      item?.forma_pagto_id || form?.watch('forma_pagto_id') || null,
    autenticacao: item?.autenticacao || '',
  };
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues,
  });
  const { drops } = useContext(DropsContext);
  const { closeDialog } = useDialog();

  const modalidade = findOnArray(
    watch('forma_pagto_id'),
    drops?.FormaDePagamento,
    'modalidade'
  );
  const isCartao =
    modalidade === '03-CARTAO DE CREDITO' ||
    modalidade === '04-CARTAO DE DEBITO';

  const onSubmit = (values) => {
    const pagamento = item
      ? form
          ?.watch('pagamento')
          ?.map((i, idx) => (index === idx ? { ...i, ...values } : i))
      : [...form?.watch('pagamento'), values];

    form?.setValue('pagamento', pagamento);
    closeDialog();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Dropdown
          name="forma_pagto_id"
          control={control}
          label="Forma de Pagamento"
          onValueChange={() => setValue('autenticacao', '')}
          options={drops?.FormaDePagamento?.filter(
            (f) =>
              f?.caixa === 'SIM' &&
              f?.modulo !== 'COMPRAS' &&
              f?.modulo !== 'CHEQUES'
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <InputMask name="valor" control={control} label="Valor" />
      </Grid>
      <Grid item xs={3}>
        <Input
          name="autenticacao"
          control={control}
          label="SKU (Autenticação)"
          disabled={!isCartao}
        />
      </Grid>
      <Grid item xs={3}>
        <InputMask
          name="dtvenc"
          control={control}
          label="Data de Vencimento"
          type="date"
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default PagamentoModal;
