import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { encryptURL } from 'utils/functions';
import { VendaExternaContext } from 'contexts/VendaExternaContext';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const VendaExternaItens = () => {
  const rotina = 'RemessasVendaExternaItens';
  const titulo = 'Itens de Vendas Externas (Fora do Estabelecimento)';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { vendaExternaItens, getVendaExternaItens, getLoading } =
    useContext(VendaExternaContext);
  const { control, getValues, reset } = useForm({
    defaultValues: vendaExternaItens?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getVendaExternaItens({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({
          rotina,
          filter: getValues(),
          order: vendaExternaItens?.order,
        }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({
          rotina,
          filter: getValues(),
          order: vendaExternaItens?.order,
        }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={vendaExternaItens?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    // {
    //   name: 'Consignar',
    //   icon: 'change_circle',
    //   action: (selected) => {
    //     const itens = selected?.map((s) => ({
    //       ...s,
    //       quantidade_max: s?.quantidade,
    //       preco_max: s?.preco,
    //       subtotal:
    //         (s?.quantidade || 0) * (s?.preco || 0) + (s?.desconto_vlr || 0),
    //     }));
    //     navigate(`/app/Remessas/Consignar`, {
    //       state: { itens, vendedor_id: user?.entidade_id },
    //     });
    //   },
    // },
    {
      name: 'Vender',
      icon: 'monetization_on',
      action: (selected) => {
        const itens = selected?.map((s) => s?.id);
        const hash = encryptURL({ itens });
        navigate(`/app/Remessas/Venda/${hash}`);
      },
    },
  ];

  const rowOptions = [
    {
      name: 'Resumo da Remessa',
      icon: 'summarize',
      menu: true,
      action: ({ row }) =>
        navigate(`/app/Remessas/Resumo/${row?.documento_id}`),
    },
  ];

  return (
    <Container>
      <Header
        titulo={titulo}
        rotina={rotina}
        colunas={vendaExternaItens?.colunas}
      />
      <Card>
        <Grid
          grid={vendaExternaItens}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={vendaExternaItens} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default VendaExternaItens;
