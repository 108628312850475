import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'contexts/AuthContext';
import InputMask from 'components/InputMask';
import Button from 'components/Button';

const ItemModal = ({ item, onSubmit }) => {
  const { user } = useContext(AuthContext);
  const defaultValues = {
    id: item?.id,
    quantidade: item?.quantidade || '',
    desconto1_pc: item?.desconto1_pc || '',
    desconto_vlr: item?.desconto_vlr || '',
    quantidade_max: item?.quantidade_max || '',
    preco: item?.preco || '',
  };
  const { control, handleSubmit, getValues, setValue } = useForm({
    defaultValues,
  });

  const onCalcDesc = (type) => {
    const {
      preco = 0,
      quantidade = 0,
      desconto1_pc = 0,
      desconto_vlr = 0,
    } = getValues();
    if (type === 'valor') {
      const value = (quantidade * preco * desconto1_pc) / 100;
      setValue('desconto_vlr', value);
    }
    if (type === 'percentual') {
      const value = (quantidade * preco) / desconto_vlr;
      setValue('desconto1_pc', value);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <InputMask name="quantidade" control={control} label="Quantidade" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask
          name="preco"
          control={control}
          label="Preco"
          disabled={user?.permissoes?.block_altera_precos}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask
          name="desconto1_pc"
          control={control}
          label="% de Desconto"
          onBlur={() => onCalcDesc('valor')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask
          name="desconto_vlr"
          control={control}
          label="Valor de Desconto"
          onBlur={() => onCalcDesc('percentual')}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default ItemModal;
