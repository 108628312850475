import React, { useContext } from 'react';
import { DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { NotasContext } from 'contexts/NotasContext';
import { useModal } from 'components/Modals';
import InputMask from 'components/InputMask';
import Button from 'components/Button';
import moment from 'moment';

const InutilizadosModal = () => {
  const { closeModal } = useModal();
  const { exportXMLInutil, postLoading } = useContext(NotasContext);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      i: moment().format('YYYY-MM-DD HH:mm:ss'),
      f: moment().format('YYYY-MM-DD HH:mm:ss'),
    },
  });

  const onSubmit = (data) => {
    exportXMLInutil({
      data,
      cb: () => closeModal(),
    });
  };

  return (
    <>
      <DialogTitle>Exportar XML Inutilizados</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6}>
            <InputMask
              name="i"
              control={control}
              label="Data de Início"
              type="date"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="f"
              control={control}
              label="Data de Término"
              type="date"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)} loading={postLoading}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default InutilizadosModal;
