import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, ButtonBase, Grid, Typography } from '@mui/material';
import { GarantiasContext } from 'contexts/GarantiasContext';
import Container from 'components/Container';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Card from 'components/Card';
import styles from './styles';
import moment from 'moment';

const Resumo = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { getResumo, getLoading, resumo } = useContext(GarantiasContext);

  useEffect(() => {
    if (state?.id) {
      getResumo({ id: state?.id });
    } else {
      navigate(-1);
    }
  }, []);

  const onSelectDocumento = (documento) => {
    if (documento?.operacao === 'ENTRADA') {
      window.open(
        `https://suprimentos.eprom2.com.br/app/Documento/${documento?.id}`
      );
    }
    if (documento?.operacao === 'SAIDA') {
      if (documento?.titulo === 'Conserto interno') {
        window.open(
          `https://servicos.eprom2.com.br/app/Ordem/${documento?.id}`
        );
      } else {
        navigate(`/app/Documento/${documento?.id}`);
      }
    }
  };

  const Line = ({ value, label, onClick = () => {} }) => (
    <Box display="flex" alignItems="center" textAlign="start">
      <Typography sx={styles?.lineLabel} variant="subtitle2">
        {label}:
      </Typography>
      <Typography sx={styles?.lineValue} variant="body2" onClick={onClick}>
        {value || '-'}
      </Typography>
    </Box>
  );

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Resumo" subtitulo={state?.produto} />
      <Grid container spacing={2}>
        {resumo?.map((r) => (
          <Grid item xs={12} sm={6} key={r?.id?.toString()} display="flex">
            <ButtonBase
              style={styles?.card}
              onClick={() => onSelectDocumento(r)}
            >
              <Card style={styles?.card} title={r?.titulo}>
                <Line
                  label="Data de Emissão"
                  value={
                    moment(r?.dtemissao).isValid() &&
                    moment(r?.dtemissao).format('DD/MM/YYYY')
                  }
                />
                <Line label="Nº do Documento" value={r?.documento} />
                <Line label="Entidade" value={r?.nome} />
                <Line label="Usuário" value={r?.name} />
                <Line label="Estoque" value={r?.estoque} />
              </Card>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Resumo;
