import React from 'react';
import Produto from './Produto';
import Cliente from './Cliente';
import Pagamento from './Pagamento';
import Adicionais from './Adicionais';

const Tabs = ({ ...props }) => {
  return (
    <>
      <Produto {...props} />
      <Cliente {...props} />
      <Pagamento {...props} />
      <Adicionais {...props} />
    </>
  );
};

export default Tabs;
