import { useContext, useEffect } from 'react';
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Link } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Controller, useForm } from 'react-hook-form';
import { removeFalseKeys } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog, useModal } from 'components/Modals';
import VirtualDrop from 'components/VirtualDrop';
import Loader from 'components/Loader';
import Button from 'components/Button';
import localeText from 'utils/localeText';
import VinculoModal from './VinculoModal';
import moment from 'moment';

const EnvioModal = ({ itens = [], reload }) => {
  const defaultValues = { cadastro_id: null, type: '42', itens: [] };
  const { openModal, closeModal } = useModal();
  const { closeDialog } = useDialog();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { getLoading, getDocumentoItens, postLoading, postDocumento } =
    useContext(DocumentosContext);
  const { control, handleSubmit, watch, setValue, getValues } = useForm({
    defaultValues,
  });
  const styles = {
    radioContainer: { flexDirection: 'row' },
    radio: { flex: 1, m: 0 },
  };

  useEffect(() => {
    getDocumentoItens({
      id: itens?.map((i) => i?.id),
      cb: (res) => setValue('itens', res),
    });
  }, []);

  const onLink = ({ vinculo, item }) => {
    const { itens } = getValues();
    const newItens = itens?.map((i) =>
      i?.id === item?.id ? { ...i, vinculo } : i
    );
    setValue('itens', newItens);
    closeModal();
  };

  const onSubmit = (values) => {
    const Itens = values?.itens?.map((item, i) => {
      item['Origens'] = [{ documento_item_org_id: item?.id }];
      if (Boolean(item?.vinculo?.id)) {
        item['Vinculos'] = [{ documento_item_id_vinculo: item?.vinculo?.id }];
      }
      item['sequencia'] = i + 1;
      delete item?.UnidadeMedida;
      delete item?.documento_id;
      delete item?.sp_custo;
      delete item?.vinculo;
      delete item?.id;
      return item;
    });
    const data = {
      Documento: {
        especie_id: 10,
        users_id: user?.id,
        filial_id: user?.filial_id,
        cadastro_id: values?.cadastro_id,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
        natureza_operacao_id: Number(values?.type),
      },
      Itens,
    };
    removeFalseKeys(data);

    postDocumento({
      data,
      cb: () => {
        closeDialog();
        if (Boolean(reload)) {
          reload();
        }
      },
    });
  };

  const renderVinculo = ({ row }) => {
    let str = '';
    if (Boolean(row?.vinculo?.natureza)) {
      str += row?.vinculo?.natureza;
    }
    if (Boolean(row?.vinculo?.documento)) {
      str += `, Nº ${row?.vinculo?.documento}`;
    }
    if (
      Boolean(row?.vinculo?.dtemissao) &&
      moment(row?.vinculo?.dtemissao).isValid()
    ) {
      str += `, Emitido em ${moment(row?.vinculo?.dtemissao).format(
        'DD/MM/YYYY [às] HH:mm'
      )}`;
    }
    return str;
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" align="center">
          Informações Gerais
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <RadioGroup {...field} sx={styles?.radioContainer}>
              <FormControlLabel
                value="7"
                control={<Radio />}
                label="Devolução"
                sx={styles?.radio}
              />
              <FormControlLabel
                value="42"
                control={<Radio />}
                label="Remessa em Garantia"
                sx={styles?.radio}
              />
              <FormControlLabel
                value="48"
                control={<Radio />}
                label="Remessa para Troca em Garantia"
                sx={styles?.radio}
              />
            </RadioGroup>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <VirtualDrop
          name="cadastro_id"
          control={control}
          label="Fornecedor"
          options={drops?.Entidade}
          refresh="Entidade"
        />
      </Grid>
      {watch('type') === '7' && (
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Itens
          </Typography>
        </Grid>
      )}
      {watch('type') === '7' && (
        <Grid item xs={12}>
          <DataGrid
            rows={watch('itens')}
            columns={[
              {
                field: 'descricao',
                headerName: 'Descrição',
                flex: 1,
                sortable: false,
              },
              {
                field: 'vinculo',
                headerName: 'Vinculo',
                flex: 1,
                sortable: false,
                valueGetter: renderVinculo,
              },
              {
                field: 'actions',
                type: 'actions',
                width: 100,
                headerName: 'Ações',
                getActions: ({ row }) => {
                  return [
                    <GridActionsCellItem
                      icon={<Link />}
                      label="Vincular"
                      onClick={() =>
                        openModal(
                          <VinculoModal
                            item={row}
                            onSubmit={onLink}
                            filial_id={user?.filial_id}
                            cadastro_id={watch('cadastro_id')}
                          />,
                          90
                        )
                      }
                    />,
                  ];
                },
              },
            ]}
            hideFooter
            autoHeight
            density="compact"
            disableSelectionOnClick
            disableColumnMenu
            showCellRightBorder
            showColumnRightBorder
            localeText={localeText}
          />
        </Grid>
      )}
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Enviar
        </Button>
      </Grid>
    </Grid>
  );
};

export default EnvioModal;
