import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  status: [],
  documento: '',
  dtemissao: {
    i: null,
    f: null,
  },
  user: [],
  filial: [],
  vendedor: [],
  cliente: [],
  fabricante: '',
  produto: '',
  quantidade: '',
  preco: '',
  unidade_medida: [],
  marca: [],
};

export const GarantiasContext = createContext();

export const GarantiasProvider = ({ children }) => {
  const [garantias, setGarantias] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtemissao', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });
  const [getLoading, setGetLoading] = useState(false);
  const [resumo, setResumo] = useState([]);

  const getGarantias = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: garantias?.filter,
        page: garantias?.page,
        size: garantias?.size,
        order: garantias?.order,
        ...payload,
      });
      setGarantias((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getResumo = async (params) => {
    try {
      setResumo([]);
      setGetLoading(true);
      const { data } = await api.get('/Comercial/Garantias/Resumo', { params });
      setResumo(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <GarantiasContext.Provider
      value={{
        garantias,
        getLoading,
        getGarantias,
        resumo,
        getResumo,
      }}
    >
      {children}
    </GarantiasContext.Provider>
  );
};
