import React, { useContext, useEffect } from 'react';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { summarizer, findOnArray } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { DropsContext } from 'contexts/DropsContext';
import { VendaContext } from 'contexts/VendaContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog } from 'components/Modals';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import TableContainer from 'components/TableContainer';
import moment from 'moment';

const FechamentoModal = ({ callback }) => {
  const defaultValues = {
    forma_pagto_id: null,
    modalidade: null,
    valor: '',
    informados: [],
    cxconta_id: null,
    planoconta_id: null,
  };
  const { control, handleSubmit, resetField, setValue, watch } = useForm({
    defaultValues,
  });
  const { closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { user } = useContext(AuthContext);
  const {
    getLoading,
    lancamentos,
    getLancamentos,
    getContas,
    contas,
    postLancamento,
    postLoading,
  } = useContext(VendaContext);
  const valores_informados = summarizer(watch('informados'), 'valor') || 0;
  const valores_registrados = summarizer(lancamentos, 'valor') || 0;
  const valores_especie =
    summarizer(
      watch('informados')?.filter((f) => f?.forma_pagto_id === 1),
      'valor'
    ) || 0;

  useEffect(() => {
    getLancamentos({
      seqcaixa: contas?.seqcaixa,
      conta: contas?.cxconta_id,
    });
  }, []);

  const onAdd = (values) => {
    if (!Boolean(values?.forma_pagto_id) || !Boolean(values?.valor)) {
      return toastWarning('Preencha todos os campos');
    }
    let informados = watch('informados');
    if (informados?.some((i) => i?.forma_pagto_id === values?.forma_pagto_id)) {
      informados = informados?.map((i) => {
        if (i?.forma_pagto_id === values?.forma_pagto_id) {
          return { ...i, valor: i?.valor + values?.valor };
        }
        return i;
      });
    } else {
      informados.push({
        valor: values?.valor,
        modalidade: values?.modalidade,
        forma_pagto_id: values?.forma_pagto_id,
      });
    }
    setValue('informados', informados);
    resetField('valor');
    resetField('modalidade');
    resetField('forma_pagto_id');
  };

  const onDelete = (index) => {
    setValue(
      'informados',
      watch('informados')?.filter((f, i) => index !== i)
    );
  };

  const onSubmit = (values) => {
    const redirect =
      Boolean(valores_especie > 0) &&
      Boolean(values?.cxconta_id) &&
      Boolean(values?.planoconta_id);
    const lanctos = lancamentos?.filter(
      (f) =>
        f?.conta !== '93' &&
        f?.conta !== '94' &&
        f?.conta !== '95' &&
        f?.conta !== '99'
    );
    const cxlancto_det = [
      {
        valor: Number(
          (
            summarizer(values?.informados, 'valor') -
            (redirect ? valores_especie : 0)
          )?.toFixed(2)
        ),
        historico: contas?.plano_fechamento,
        planoconta_id: contas?.plano_fechamento_id,
      },
    ];
    const diff = Number(
      (
        (summarizer(values?.informados, 'valor') || 0) -
        (summarizer(lanctos, 'valor') || 0)
      ).toFixed(2)
    );

    if (diff < 0) {
      cxlancto_det?.unshift({
        valor: Math.abs(diff),
        historico: contas?.plano_falta,
        planoconta_id: contas?.plano_falta_id,
      });
    }

    if (diff > 0) {
      cxlancto_det?.unshift({
        valor: Math.abs(diff),
        historico: contas?.plano_sobra,
        planoconta_id: contas?.plano_sobra_id,
      });
    }

    const data = [
      {
        fechamento: true,
        conta_id: contas?.cxconta_id,
        seqcaixa: contas?.seqcaixa,
        dtemis: moment().format('YYYY-MM-DD'),
        dtlanc: moment().format('YYYY-MM-DD'),
        dtlanc_org: moment().format('YYYY-MM-DD'),
        conciliado: 'NAO',
        cxlancto_det,
      },
    ];

    if (redirect) {
      cxlancto_det?.unshift({
        valor: valores_especie,
        historico: contas?.plano_transferencia,
        planoconta_id: contas?.plano_transferencia_id,
      });
      data.push({
        conta_id: values?.cxconta_id,
        seqcaixa: contas?.seqcaixa,
        dtemis: moment().format('YYYY-MM-DD'),
        dtlanc: moment().format('YYYY-MM-DD'),
        dtlanc_org: moment().format('YYYY-MM-DD'),
        conciliado: 'NAO',
        cxlancto_det: [
          {
            valor: valores_especie,
            planoconta_id: values?.planoconta_id,
            historico: findOnArray(
              values?.planoconta_id,
              drops?.Planoconta,
              'label'
            ),
          },
        ],
      });
    }

    postLancamento({
      data,
      cb: () => {
        closeDialog();
        getContas();
      },
    });
  };

  if (getLoading) {
    return <Loader />;
  }
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div">
          Dados do Fechamento
        </Typography>
        <Typography variant="body1" component="div">
          Usuário: {user?.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="div">
              Valores Informados (
              {valores_informados?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
              )
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="forma_pagto_id"
              control={control}
              label="Forma de Pagamento"
              options={drops?.FormaDePagamento?.filter(
                (f) =>
                  f?.caixa === 'SIM' &&
                  f?.modulo !== 'COMPRAS' &&
                  f?.modulo !== 'CHEQUES'
              )}
              onValueChange={(v) => setValue('modalidade', v?.modalidade)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask name="valor" control={control} label="Valor" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onAdd)}
            >
              ADICIONAR
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Forma de Pagamento</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {watch('informados')?.map((item, index) => (
                    <TableRow key={index?.toString()}>
                      <TableCell>
                        {findOnArray(
                          item?.forma_pagto_id,
                          drops?.FormaDePagamento,
                          'label'
                        )}
                      </TableCell>
                      <TableCell>
                        {item?.valor?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => onDelete(index)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="div">
              Valores Registrados (
              {valores_registrados?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
              )
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Conta</TableCell>
                    <TableCell>Forma de Pagamento</TableCell>
                    <TableCell>Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lancamentos?.map((item, index) => (
                    <TableRow key={index?.toString()}>
                      <TableCell>{item?.conta}</TableCell>
                      <TableCell>{item?.descricao}</TableCell>
                      <TableCell>
                        {item?.valor?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="div">
              Direcionar Valores em Espécie (
              {valores_especie?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
              )
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="cxconta_id"
              control={control}
              label="Conta"
              options={drops?.Cxconta}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="planoconta_id"
              control={control}
              label="Plano de Conta"
              options={drops?.Planoconta?.filter(
                (p) => p?.operacional === 'SIM' && p?.Operação === 'DEBITO'
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          loading={postLoading}
          onClick={handleSubmit(onSubmit)}
        >
          SALVAR
        </Button>
      </Grid>
    </Grid>
  );
};

export default FechamentoModal;
