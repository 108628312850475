import React, { useContext, useEffect } from 'react';
import { Box, Grid, Icon, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { summarizer, decryptURL } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { AuthContext } from 'contexts/AuthContext';
import Container from 'components/Container';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import styles from './styles';
import moment from 'moment';

const Gerar = () => {
  const especie_id = 46;
  const natureza_operacao_id = 54;
  const { hash } = useParams();
  const { user } = useContext(AuthContext);
  const { postDocumento, postLoading, getDocumento, getLoading, documento } =
    useContext(DocumentosContext);
  const defaultValues = {
    dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
    observacao: '',
    itens: [],
  };
  const navigate = useNavigate();
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });
  const decrypt = decryptURL(hash);

  const renderValues = () => {
    let itens = documento?.DocumentoItems?.map((item) => {
      const Aprovacoes = item?.DocumentoItemAprovacaos?.map((a) => {
        delete a?.documento_item_id;
        delete a?.id;
        return a;
      });
      if (Boolean(Aprovacoes?.length)) item['Aprovacoes'] = Aprovacoes;
      const gerados = item?.Destinos?.filter(
        (d) => d?.especie_id === 46 && d?.natureza_operacao_id === 54
      );
      const quantidade = item?.quantidade - summarizer(gerados, 'quantidade');
      let preco = item?.preco;
      item['quantidade_max'] = quantidade;
      item['quantidade'] = quantidade;
      item['preco_max'] = preco;
      item['preco'] = preco;
      item['calcimp'] = 'SIM';
      item['Origens'] = [{ documento_item_org_id: item?.id }];
      if (documento?.NaturezaOperacao?.terceiro === 'SIM') {
        item['Vinculos'] = [{ documento_item_id_vinculo: item?.id }];
      } else {
        delete item?.Vinculos;
      }
      item['subtotal'] =
        (item?.quantidade || 0) * (preco || 0) + (item?.desconto_vlr || 0);
      delete item?.Cemovimentos;
      delete item?.Cfo;
      delete item?.Destinos;
      delete item?.DocumentoItemAprovacaos;
      delete item?.DocumentoItemEstruturas;
      delete item?.DocumentoItemImpostos;
      delete item?.DocumentoItemMc;
      delete item?.DocumentoItemOrigems;
      delete item?.DocumentoItemProcessos;
      delete item?.DocumentoItemQualidades;
      delete item?.DocumentoItemVariacaos;
      delete item?.Kit;
      delete item?.Produto;
      delete item?.UnidadeMedida;
      delete item?.Vinculados;
      delete item?.cfo_id;
      delete item?.documento_id;
      return item;
    })?.filter((f) => Boolean(f?.quantidade));
    if (Boolean(decrypt?.itens?.length)) {
      itens = itens?.filter((f) => decrypt?.itens?.includes(f?.id));
    }
    setValue('itens', itens);
  };

  useEffect(() => {
    if (Boolean(decrypt?.id)) {
      getDocumento(decrypt?.id);
    }
  }, []);

  useEffect(() => {
    if (Boolean(decrypt?.id) && Boolean(documento)) {
      renderValues();
    }
  }, [documento]);

  const onCellEditCommit = (e) => {
    const itens = watch('itens')?.map((c) => {
      if (c?.id === e?.id) {
        if (e?.field === 'quantidade' && e?.value > c?.quantidade_max) {
          const subtotal =
            (c?.quantidade_max || 0) * (c?.preco || 0) + (c?.desconto_vlr || 0);
          return { ...c, [e?.field]: c?.quantidade_max, subtotal };
        } else {
          const subtotal =
            (e?.value || 0) * (c?.preco || 0) + (c?.desconto_vlr || 0);
          return { ...c, [e?.field]: e?.value, subtotal };
        }
      } else {
        return { ...c };
      }
    });
    setValue('itens', itens);
  };

  const onDeleteItem = (id) => {
    setValue(
      'itens',
      watch('itens')?.filter((i) => i?.id !== id)
    );
  };

  const onSubmit = (values) => {
    let data = {
      Documento: {
        especie_id,
        natureza_operacao_id,
        users_id: user?.id,
        filial_id: documento?.filial_id,
        cadastro_id: documento?.cadastro_id,
        dtemissao: values?.dtemissao,
        celocal_id: documento?.celocal_id,
        contribuinte_id: documento?.contribuinte_id,
        usofinal: documento?.usofinal,
        observacao: values?.observacao,
      },
      Saida: {
        condicao_pagamento_id: 1,
        vendedor_id: documento?.DocumentoSaida?.vendedor_id,
        documento: documento?.DocumentoSaida?.documento,
        validade: documento?.DocumentoSaida?.validade,
        dtsaida: documento?.DocumentoSaida?.dtsaida,
        dtatend: documento?.DocumentoSaida?.dtatend,
        desc1: documento?.DocumentoSaida?.desc1,
        desc2: documento?.DocumentoSaida?.desc2,
        desc3: documento?.DocumentoSaida?.desc3,
        desc4: documento?.DocumentoSaida?.desc4,
        desc5: documento?.DocumentoSaida?.desc5,
      },
      Itens: values?.itens?.map((item, index) => {
        delete item?.id;
        return { ...item, sequencia: index + 1 };
      }),
    };
    if (Boolean(documento?.id)) {
      const documento_id = documento?.id;
      if (documento?.especie_id === 16) {
        data = { ...data, Origens: [{ documento_id, status_id: 21 }] };
      } else {
        data = { ...data, Origens: [{ documento_id }] };
      }
    }
    postDocumento({ data, cb: () => navigate(-1) });
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Gerar Garantia" />
      <Card title="Itens">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DataGrid
              rows={watch('itens')}
              columns={[
                {
                  field: 'referencia',
                  headerName: 'Referência',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'descricao',
                  headerName: 'Descrição',
                  flex: 2,
                  sortable: false,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  flex: 1,
                  type: 'number',
                  sortable: false,
                  editable: true,
                },
                {
                  field: 'desconto_vlr',
                  headerName: 'Desconto',
                  type: 'number',
                  valueGetter: ({ value }) =>
                    (value || 0)?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'preco',
                  headerName: 'Preço Unitário',
                  type: 'number',
                  valueGetter: ({ value }) =>
                    (value || 0)?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'subtotal',
                  headerName: 'Subtotal',
                  type: 'number',
                  valueGetter: ({ value }) =>
                    (value || 0)?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'actions',
                  headerName: 'Ações',
                  type: 'actions',
                  flex: 1,
                  getActions: ({ id }) => [
                    <GridActionsCellItem
                      icon={<Icon>delete</Icon>}
                      label="Excluir"
                      onClick={() => onDeleteItem(id)}
                    />,
                  ],
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              onCellEditCommit={onCellEditCommit}
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" align="center">
              Total:{' '}
              {(summarizer(watch('itens'), 'subtotal') || 0)?.toLocaleString(
                'pt-br',
                {
                  style: 'currency',
                  currency: 'BRL',
                }
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Informações Adicionais" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Box textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Box>
    </Container>
  );
};

export default Gerar;
