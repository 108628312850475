import React from 'react';
import { useForm } from 'react-hook-form';
import { DialogContent, Fab, Grid, Typography } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';
import { findOnArray } from 'utils/functions';
import { useModal } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import styles from '../styles';
import moment from 'moment';

const FormaDeEnvio = ({ drops, loading, onSubmit, documento = {} }) => {
  const { openModal, closeModal } = useModal();
  const defaultValues = {
    documento_id: documento?.id,
    forma_envio_id: documento?.DocumentoSaida?.forma_envio_id || null,
    freteenv: documento?.DocumentoSaida?.freteenv || '',
    fretecob: documento?.DocumentoSaida?.fretecob || '',
    freterec: documento?.DocumentoSaida?.freterec || '',
  };
  const { control, handleSubmit } = useForm({ defaultValues });

  const Modal = () => {
    const defaultValues = { whatsapp: '' };
    const { control, handleSubmit } = useForm({ defaultValues });

    const onSend = (values) => {
      let phone = values?.whatsapp?.replace(/\D/g, '');
      if (Boolean(phone)) {
        const emitente = findOnArray(
          documento?.filial_id,
          drops?.Filial,
          'label'
        );
        phone = '+55' + phone;
        let message = `Prezado cliente, segue detalhes do envio referente sua Nota fiscal`;
        if (Boolean(documento?.documento)) {
          message += ` ${documento?.documento}`;
        }
        if (Boolean(emitente)) {
          message += `, emitida por ${emitente}`;
        }
        if (moment(documento?.dtemissao).isValid()) {
          message += ` em ${moment(documento?.dtemissao).format('DD/MM/YYYY')}`;
        }
        if (Boolean(documento?.DocumentoSaida?.freteenv)) {
          message += `, ${documento?.DocumentoSaida?.freteenv}`;
        }
        const url = `https://api.whatsapp.com/send?phone=${phone}&text=${message}&app_absent=0`;
        window.open(url);
        onSubmit({
          Registros: [
            {
              documento_id: documento?.id,
              historico: `Detalhes do envio encaminhado para ${values?.whatsapp}`,
            },
          ],
        });
      }
      closeModal();
    };

    return (
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Enviar via WhatsApp</Typography>
          </Grid>
          <Grid item xs={12}>
            <InputMask
              name="whatsapp"
              label="WhatsApp"
              control={control}
              mask="(99) 99999-9999"
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button variant="contained" onClick={handleSubmit(onSend)}>
              Enviar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    );
  };

  return (
    <Card title="Forma de Envio" style={styles?.card}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="forma_envio_id"
            control={control}
            label="Forma de Envio"
            options={drops?.FormaDeEnvio}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="freteenv" control={control} label="Detalhes do Envio" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask name="fretecob" control={control} label="Frete Cobrado" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask name="freterec" control={control} label="Frete Recebido" />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            color="primary"
            variant="outlined"
            loading={loading}
            onClick={handleSubmit((Saida) => onSubmit({ Saida }))}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
      <Fab
        color="primary"
        sx={styles?.fab}
        onClick={() => openModal(<Modal />)}
      >
        <WhatsApp />
      </Fab>
    </Card>
  );
};

export default FormaDeEnvio;
