import { useContext, useEffect, useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { DashboardContext } from 'contexts/DashboardContext';
import { AuthContext } from 'contexts/AuthContext';
import { defaultTheme } from 'utils/theme';
import Container from 'components/Container';
import Loader from 'components/Loader';
import Card from 'components/Card';
import Pizza from './Charts/Pizza';
import Bar from './Charts/Bar';

const App = () => {
  const [tab, setTab] = useState(0);
  const { user } = useContext(AuthContext);
  const { dashData, getLoading, getDashboard } = useContext(DashboardContext);
  const { primary, secondary } = defaultTheme?.palette;

  const mockData = [
    { id: 0, color: primary?.dark },
    { id: 1, color: primary?.light },
    { id: 2, color: secondary?.dark },
  ];

  const renderGraph = (type) =>
    (dashData?.[type] || [])?.map((d, i) => ({ ...d, ...mockData[i] }));

  useEffect(() => {
    getDashboard();
  }, []);

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Grid container spacing={5}>
        {!user?.permissoes?.block_dashboard_orcamentos && (
          <Grid item xs={12} sm={4} display="flex">
            <Pizza title="Orçamentos" data={renderGraph('orçamentos')} />
          </Grid>
        )}
        {!user?.permissoes?.block_dashboard_pedidos && (
          <Grid item xs={12} sm={4} display="flex">
            <Pizza title="Pedidos" data={renderGraph('pedidos')} />
          </Grid>
        )}
        {!user?.permissoes?.block_dashboard_faturas && (
          <Grid item xs={12} sm={4} display="flex">
            <Pizza title="Faturas" data={renderGraph('faturas')} />
          </Grid>
        )}
        {!user?.permissoes?.block_dashboard_faturamentos && (
          <Grid item xs={12}>
            <Card title="Faturamentos por Vendedor">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Tabs
                    variant="fullWidth"
                    value={tab}
                    onChange={(_, v) => setTab(v)}
                  >
                    <Tab value={0} label="Quantidade" />
                    <Tab value={1} label="Valor" />
                  </Tabs>
                </Grid>
                <Grid item xs={12}>
                  {tab === 0 && (
                    <Bar
                      axisData={dashData?.faturamentos?.map((f) => f?.nome)}
                      seriesData={dashData?.faturamentos?.map(
                        (f) => f?.quantidade
                      )}
                    />
                  )}
                  {tab === 1 && (
                    <Bar
                      axisData={dashData?.faturamentos?.map((f) => f?.nome)}
                      seriesData={dashData?.faturamentos?.map((f) => f?.valor)}
                    />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default App;
