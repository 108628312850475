import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import {
  CreditCard,
  Delete,
  Edit,
  MoreVert,
  Person,
  ShoppingCart,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { summarizer, getDatePagto, findOnArray } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { VendaContext } from 'contexts/VendaContext';
import { useDialog, useModal } from 'components/Modals';
import TableContainer from 'components/TableContainer';
import ButtonMenu from 'components/ButtonMenu';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Header from 'components/Header';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Card from 'components/Card';
import ItemModal from './Modals/ItemModal';
import ResumoModal from './Modals/ResumoModal';
import PagamentoModal from './Modals/PagamentoModal';
import LançamentoModal from './Modals/LançamentoModal';
import FechamentoModal from './Modals/FechamentoModal';
import CustomTabs from './Tabs';
import styles from './styles';
import moment from 'moment';
import api from 'services/api';

const Caixa = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [itens, setItens] = useState([]);
  const { id, type } = useParams();
  const { user } = useContext(AuthContext);
  const defaultValues = {
    //ABERTURA
    troco: null,
    //PRODUTO
    tabpreco_id: null,
    vendedor_id: user?.entidade_id,
    produto_id: null,
    cdbarra: '',
    quantidade: '',
    desconto1_pc: '',
    desconto_vlr: '',
    preco: '',
    infadc: '',
    //CLIENTE
    pessoa: 'FISICA',
    cadastro_id: null,
    cnpj: '',
    cpf: '',
    //PAGAMENTO
    forma_pagto_id: null,
    condicao_pagamento_id: null,
    valor: '',
    autenticacao: '',
    pagamento: [],
    //ADICIONAIS
    dadosadc: '',
  };
  const form = useForm({ defaultValues });
  const { drops } = useContext(DropsContext);
  const {
    contas,
    getLoading,
    postLoading,
    postLancamento,
    getContas,
    deleteUltimaVenda,
  } = useContext(VendaContext);
  const { openDialog } = useDialog();
  const { openModal } = useModal();

  const tabs = [
    {
      value: 0,
      key: 'Produto',
      label: 'Produto',
      icon: <ShoppingCart />,
    },
    {
      value: 1,
      key: 'Cliente',
      label: 'Cliente',
      icon: <Person />,
    },
    {
      value: 2,
      key: 'Pagamento',
      label: 'Pagamento',
      icon: <CreditCard />,
    },
    {
      value: 3,
      key: 'Adicionais',
      label: 'Adicionais',
      icon: <MoreVert />,
    },
  ];

  const onSubmit = (values) => {
    if (!Boolean(itens?.length)) {
      return toastWarning('Insira ao menos um item');
    }
    if (!Boolean(values?.pagamento?.length)) {
      return toastWarning('Insira uma Forma de Pagamento');
    }
    openDialog(
      <ResumoModal
        itens={itens}
        values={values}
        origem_id={id}
        onReset={onCancel}
        mainType={type}
      />,
      'Resumo da Venda'
    );
  };

  const onCancel = () => {
    form?.reset();
    setItens([]);
    setTab(0);
    if (Boolean(type)) {
      navigate('/app/Caixa');
    }
  };

  const onAddItem = (values) => {
    const { resetField, watch } = form;
    if (!Boolean(watch('produto_id'))) {
      toastWarning('Nenhum produto selecionado');
    } else {
      const item = {
        subtotal: values?.subtotal,
        descricao: values?.descricao,
        quantidade: values?.quantidade,
        desconto_vlr: values?.desconto_vlr,
        preco: values?.preco,
        precobase_id: values?.precobase_id,
        produto_id: values?.produto_id,
        desconto1_pc: values?.desconto1_pc,
        infadc: values?.infadc,
      };
      setItens((prev) => [...prev, item]);
      resetField('produto_id');
      resetField('cdbarra');
      resetField('quantidade');
      resetField('desconto1_pc');
      resetField('desconto_vlr');
      resetField('preco');
      resetField('infadc');
    }
  };

  const onDeleteItem = (index) => {
    setItens(itens?.filter((_, i) => index !== i));
  };

  const onChangePagto = (props) =>
    openDialog(<PagamentoModal {...props} form={form} />, 'Editar Parcela');

  const onAddPagto = (values) => {
    const { setValue } = form;
    if (!Boolean(values?.valor)) {
      return toastWarning('Valor não informado');
    } else if (!Boolean(values?.forma_pagto_id)) {
      return toastWarning('Forma de pagamento não informada');
    } else if (!Boolean(values?.condicao_pagamento_id)) {
      return toastWarning('Condição de pagamento não informada');
    } else {
      const pagamento = [];
      let valor_lancado = 0;
      const condicao = findOnArray(
        values?.condicao_pagamento_id,
        findOnArray(
          values?.forma_pagto_id,
          drops?.FormaDePagamento,
          'condicoes'
        )
      );
      for (let parcela = 1; parcela <= condicao?.nvezes; parcela++) {
        const valor = parseFloat((values?.valor / condicao?.nvezes).toFixed(2));
        pagamento.push({
          parcela,
          dtvenc: getDatePagto({ ...condicao, parcela }),
          valor:
            parcela === condicao?.nvezes
              ? parseFloat((values?.valor - valor_lancado).toFixed(2))
              : valor,
          forma_pagto_id: values?.forma_pagto_id,
          autenticacao: values?.autenticacao,
        });
        valor_lancado += valor;
      }
      setValue('pagamento', [...values?.pagamento, ...pagamento]);
    }
  };

  const onDeletePagto = (index) => {
    const { watch, setValue } = form;
    setValue(
      'pagamento',
      watch('pagamento')?.filter((_, i) => index !== i)
    );
  };

  const onSubmitAbertura = (values) => {
    const data = [
      {
        abertura: true,
        conta_id: contas?.cxconta_id,
        dtemis: moment().format('YYYY-MM-DD'),
        dtlanc: moment().format('YYYY-MM-DD'),
        dtlanc_org: moment().format('YYYY-MM-DD'),
        conciliado: 'NAO',
        cxlancto_det: [
          {
            valor: values?.troco,
            historico: contas?.plano_abertura,
            planoconta_id: contas?.plano_abertura_id,
          },
        ],
      },
    ];

    postLancamento({
      data,
      cb: () => {
        form?.reset();
        getContas();
      },
    });
  };

  const loadDocumento = async () => {
    const { data } = await api.get(`/Cadastros/Documento/${id}`, {
      params: { Itens: true, Saida: true },
    });
    if (data?.cadastro_id) {
      form?.setValue('cadastro_id', data?.cadastro_id);
    }
    if (data?.DocumentoSaida?.vendedor_id) {
      form?.setValue('vendedor_id', data?.DocumentoSaida?.vendedor_id);
    }
    if (data?.documento && data?.especie_id === 16) {
      form?.setValue('dadosadc', `ORDEM DE SERVIÇO: ${data?.documento}`);
    }
    if (data?.DocumentoItems) {
      let loadedItens = [...data?.DocumentoItems];
      if (type === 'NFSe') {
        loadedItens = loadedItens?.filter((f) => f?.Cfo?.servico === 'SIM');
      }
      if (type === 'NFe' || type === 'CFe') {
        loadedItens = loadedItens?.filter((f) => f?.Cfo?.servico !== 'SIM');
      }
      const moved = loadedItens?.some((di) =>
        Boolean(di?.Cemovimentos?.length)
      );
      const renderedItens = loadedItens
        ?.map((i) => {
          const gerados = i?.Destinos?.filter(
            (d) =>
              d?.especie_natureza === 'FATURA' && d?.especie_grupo === 'SAIDA'
          );
          const movimentados = Math.abs(
            summarizer(i?.Cemovimentos, 'quantidade') || 0
          );
          let quantidade =
            (moved ? movimentados || 0 : i?.quantidade || 0) -
            summarizer(gerados, 'quantidade');
          let preco = i?.preco;
          const iss = (i?.iss_retido || 0) * (preco || 0) * (quantidade || 0);
          const iss_ret = Number(
            (type === 'NFSe' ? (iss !== 0 ? iss * -0.01 : iss) : 0).toFixed(2)
          );

          return {
            origem_id: i?.id,
            subtotal:
              (quantidade || 0) * (preco || 0) +
              (i?.desconto_vlr || 0) +
              iss_ret,
            descricao: i?.descricao,
            referencia: i?.referencia,
            quantidade,
            desconto_vlr: i?.desconto_vlr,
            preco,
            precobase_id: i?.precobase_id,
            iss_ret,
            produto_id: i?.produto_id,
            desconto1_pc: i?.desconto1_pc,
            infadc: i?.infadc,
          };
        })
        ?.filter(
          (f) =>
            Boolean(f?.quantidade) &&
            f?.quantidade > 0 &&
            Boolean(f?.preco) &&
            f?.preco > 0
        );
      setItens((prev) => [...prev, ...renderedItens]);
    }
  };

  useEffect(() => {
    const total =
      (summarizer(itens, 'subtotal') || 0) -
      (summarizer(form?.watch('pagamento'), 'valor') || 0);
    form?.setValue('valor', total > 0 ? Number(total?.toFixed(2)) : '');
  }, [itens, form?.watch('pagamento')]);

  useEffect(() => {
    if (id) {
      loadDocumento();
    }
  }, []);

  const options = [
    {
      name: 'Cancelar Venda Atual',
      action: onCancel,
      icon: 'cancel',
    },
    {
      name: 'Cancelar Última Venda',
      action: () => deleteUltimaVenda({ cxconta_id: contas?.cxconta_id }),
      icon: 'cancel',
    },
    {
      name: 'Sangria',
      action: () => openModal(<LançamentoModal title="Sangria" />),
      icon: 'paid',
    },
    {
      name: 'Suprimentos',
      action: () => openModal(<LançamentoModal title="Suprimentos" />),
      icon: 'widgets',
    },
    {
      name: 'Fechamento de Caixa',
      action: () => openDialog(<FechamentoModal />, 'Fechamento de Caixa'),
      icon: 'close',
    },
  ];

  if (getLoading) {
    return <Loader />;
  }

  if (!getLoading && !contas?.seqcaixa) {
    return (
      <Container>
        <Header titulo="Abertura de Caixa" />
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputMask
                name="troco"
                control={form?.control}
                label="Aporte para Troco"
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                loading={postLoading}
                onClick={form?.handleSubmit(onSubmitAbertura)}
              >
                SALVAR
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Container>
    );
  }

  return (
    <Container>
      <Header titulo="Venda" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6" color="primary">
                  Total:{' '}
                  {summarizer(itens, 'subtotal')?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
                <div>
                  <ButtonMenu options={options} />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={form?.handleSubmit(onSubmit)}
                  >
                    FINALIZAR
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} display="flex">
          <Card style={styles?.card}>
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={(_, v) => setTab(v)}
              TabIndicatorProps={{ sx: { display: 'none' } }}
              sx={{
                margin: '1em 0',
                '& .MuiTabs-flexContainer': { flexWrap: 'wrap' },
              }}
            >
              {tabs.map(({ show = true, ...t }) => show && <Tab {...t} />)}
            </Tabs>
            <CustomTabs
              {...form}
              tab={tab}
              user={user}
              drops={drops}
              itens={itens}
              onAddItem={onAddItem}
              onAddPagto={onAddPagto}
              onChangePagto={onChangePagto}
              onDeletePagto={onDeletePagto}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" style={styles?.card}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Produto</TableCell>
                        <TableCell>Quantidade</TableCell>
                        <TableCell>Preço Unitário</TableCell>
                        <TableCell>Desconto</TableCell>
                        <TableCell>Imposto</TableCell>
                        <TableCell>Sub total</TableCell>
                        <TableCell align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itens?.map((item, index) => (
                        <TableRow key={index?.toString()}>
                          <TableCell>{item?.descricao}</TableCell>
                          <TableCell>{item?.quantidade}</TableCell>
                          <TableCell>
                            {item?.preco?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          <TableCell>
                            {item?.desconto_vlr?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          <TableCell>
                            {item?.iss_ret?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          <TableCell>
                            {item?.subtotal?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          {Boolean(id) ? (
                            <TableCell />
                          ) : (
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <ItemModal
                                      user={user}
                                      item={item}
                                      index={index}
                                      itens={itens}
                                      setItens={setItens}
                                    />,
                                    'Editar Item'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeleteItem(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Caixa;
