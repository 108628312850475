import React, { useContext, useEffect, useState } from 'react';
import { Grid, Icon, Box, Pagination } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { summarizer, findOnArray } from 'utils/functions';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Card from 'components/Card';
import moment from 'moment';

const Retorno = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [itens, setItens] = useState([]);
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postDocumento, postLoading, getDocumento, getLoading, documento } =
    useContext(DocumentosContext);

  const getVinculos = (natureza_operacao_id) => {
    switch (natureza_operacao_id) {
      case 19:
        return 21;
      case 22:
        return 24;
      case 37:
        return 45;
      case 39:
        return 40;
      case 42:
        return 43;
      case 48:
        return 49;
      case 52:
        return 45;
      case 56:
        return 57;
      case 62:
        return 63;
      case 78:
        return 45;
      default:
        return null;
    }
  };

  const especie_id = documento?.especie_id;
  const natureza_operacao_id = getVinculos(documento?.natureza_operacao_id);

  useEffect(() => {
    if (Boolean(id)) {
      getDocumento(id);
    }
  }, []);

  useEffect(() => {
    if (Boolean(id) && Boolean(documento)) {
      renderValues();
    }
  }, [documento]);

  const renderValues = () => {
    const itens = documento?.DocumentoItems?.map((di) => {
      let quantidade = di?.quantidade;
      let gerados = di?.Vinculos?.filter(
        (d) =>
          (d.especie_id === 46 && d.natureza_operacao_id === 54) ||
          (d?.especie_id === especie_id &&
            d?.natureza_operacao_id === natureza_operacao_id)
      );
      const quantidade_gerada =
        documento?.natureza_operacao_id === 37
          ? 0
          : summarizer(gerados, 'quantidade');
      quantidade = quantidade - quantidade_gerada;
      return { ...di, quantidade, quantidade_max: quantidade };
    })?.filter((f) => Boolean(f?.quantidade));
    setItens(itens);
  };

  const onCellEditCommit = (e) => {
    const editedRows = itens?.map((c) => {
      if (c?.id === e?.id && e?.field === 'quantidade') {
        if (e?.value > c?.quantidade_max) {
          return { ...c, [e?.field]: c?.quantidade_max };
        } else {
          return { ...c, [e?.field]: e?.value };
        }
      } else {
        return { ...c };
      }
    });
    setItens(editedRows);
  };

  const onSubmit = () => {
    const especie = findOnArray(especie_id, drops?.EspecieDocumento);
    let data = {
      Documento: {
        especie_id,
        natureza_operacao_id,
        users_id: user?.id,
        filial_id: user?.filial_id,
        cadastro_id: documento?.cadastro_id,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      Itens: itens?.map((i, index) => ({
        sequencia: index + 1,
        produto_id: i?.produto_id,
        descricao: i?.descricao,
        quantidade: i?.quantidade,
        referencia: i?.referencia,
        preco: i?.preco,
        precobase_id: i?.precobase_id,
        Vinculos: [{ documento_item_id_vinculo: i?.id }],
      })),
      Origens: [{ documento_id: id }],
    };
    if (especie?.Grupo === 'SAIDA') {
      data = { ...data, Saida: { vendedor_id: user?.entidade_id } };
    }
    if (especie?.Grupo === 'ENTRADA') {
      data = {
        ...data,
        Entrada: { dtentrada: moment().format('YYYY-MM-DD HH:mm:ss') },
      };
    }
    postDocumento({
      data,
      cb: () => navigate(-1),
    });
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Retorno de Remessa" />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ height: '75vh' }}>
              <DataGrid
                rows={itens}
                columns={[
                  {
                    field: 'referencia',
                    headerName: 'Referência',
                    flex: 1,
                    sortable: false,
                  },
                  {
                    field: 'descricao',
                    headerName: 'Produto',
                    flex: 5,
                    sortable: false,
                  },
                  {
                    field: 'quantidade',
                    headerName: 'Quantidade',
                    type: 'number',
                    flex: 1,
                    editable: documento?.natureza_operacao_id !== 37,
                    sortable: false,
                  },
                  {
                    field: 'actions',
                    headerName: 'Ações',
                    type: 'actions',
                    flex: 1,
                    getActions: (params) => [
                      <GridActionsCellItem
                        icon={<Icon>delete</Icon>}
                        label="Excluir"
                        onClick={() =>
                          setItens(itens?.filter((r) => r?.id !== params?.id))
                        }
                      />,
                    ],
                  },
                ]}
                autoPageSize
                density="compact"
                disableSelectionOnClick
                disableColumnMenu
                showCellRightBorder
                showColumnRightBorder
                onCellEditCommit={onCellEditCommit}
                localeText={{
                  noRowsLabel: 'Nenhum Registro.',
                  MuiTablePagination: {
                    labelDisplayedRows: ({ from, to, count }) =>
                      `${from}-${to} de ${count}`,
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              loading={postLoading}
              onClick={onSubmit}
            >
              FINALIZAR
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Retorno;
