import { useLayoutEffect, useRef, useState } from 'react';
import { BarChart } from '@mui/x-charts';
import { Box, Typography } from '@mui/material';
import { defaultTheme } from 'utils/theme';

const Bar = ({ axisData = [], seriesData = [] }) => {
  const ref = useRef(null);
  const [size, setSize] = useState({});
  const { primary } = defaultTheme?.palette;

  useLayoutEffect(() => {
    setSize({ width: ref.current.offsetWidth, height: 400 });
  }, []);

  return (
    <Box ref={ref} sx={{ display: 'flex', alignItems: 'center' }}>
      {Boolean(axisData?.length) && Boolean(seriesData?.length) ? (
        <BarChart
          xAxis={[{ scaleType: 'band', data: axisData }]}
          series={[{ color: primary?.dark, data: seriesData }]}
          width={
            150 * seriesData?.length < size?.width
              ? 150 * seriesData?.length
              : size?.width
          }
          height={size?.height || 0}
        />
      ) : (
        <Typography textAlign="center" variant="body2" flex={1}>
          Nenhum registro encontrado
        </Typography>
      )}
    </Box>
  );
};

export default Bar;
