const styles = {
  card: {
    marginTop: '1em',
  },
  image: {
    objectFit: 'contain',
    maxHeight: '300px',
    overflow: 'hidden',
    width: '100%',
  },
  pdf: {
    textAlign: 'center',
    maxHeight: '300px',
    overflow: 'hidden',
    width: '100%',
    padding: '2em 0',
  },
  button: {
    margin: '0 .5em',
  },
  headerLink: {
    fontWeight: 500,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  lineLabel: {
    flex: 1,
  },
  lineLabelLink: {
    flex: 1,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  lineValue: {
    flex: 3,
  },
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20,
  },
  popover: {
    p: [1],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 200,
  },
};

export default styles;
