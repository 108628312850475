import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DropsContext } from 'contexts/DropsContext';
import { toastWarning } from 'utils/toast';
import { GarantiasContext } from 'contexts/GarantiasContext';
import { GridContext } from 'contexts/GridContext';
import { useDialog, useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import EnvioModal from './Modals/EnvioModal';
import FabrincanteModal from './Modals/FabricanteModal';
import Filter from './filter';
import mock from './mock';

const Garantias = () => {
  const rotina = 'Garantias';
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { openDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { garantias, getGarantias, getLoading } = useContext(GarantiasContext);
  const { control, getValues, reset } = useForm({
    defaultValues: garantias?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getGarantias({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: garantias?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: garantias?.order }),
    },
    {
      name: 'Enviar para o Fornecedor',
      icon: 'send',
      action: (selected) => {
        if (Boolean(selected?.length)) {
          if (selected?.some((s) => s?.status !== 'PENDENTE')) {
            toastWarning('Selecione somente garantias pendentes');
          } else {
            openDialog(
              <EnvioModal itens={selected} reload={loadGrid} />,
              'Enviar para o Fornecedor'
            );
          }
        } else {
          toastWarning('Selecione ao menos um item');
        }
      },
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={garantias?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
  ];
  const rowOptions = [
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.documento_id}`),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      menu: true,
      action: ({ row }) => navigate('/app/Garantias/Resumo', { state: row }),
    },
    {
      name: 'Registrar Código do Fabricante',
      icon: 'draw',
      menu: true,
      show: ({ row }) => Boolean(row?.documento_id) && Boolean(row?.origem_id),
      action: ({ row }) =>
        openModal(
          <FabrincanteModal
            documento_id={row?.documento_id}
            origem_id={row?.origem_id}
            callback={() => {
              loadGrid();
              closeModal();
            }}
          />
        ),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} colunas={garantias?.colunas} />
      <Card>
        <Grid
          grid={garantias}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          getRowHeight={() => 'auto'}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={garantias} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Garantias;
