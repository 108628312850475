import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { QueryStats, Visibility } from '@mui/icons-material';
import { dropTipoPessoa } from 'utils/drops';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';

const Cliente = ({ tab, drops, watch, control, setValue, resetField }) => {
  const display = tab === 1 ? 'flex' : 'none';

  return (
    <Grid container spacing={2} sx={{ display }}>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="pessoa"
          control={control}
          label="Tipo de Pessoa"
          options={dropTipoPessoa}
          onValueChange={() => {
            resetField('cpf');
            resetField('cnpj');
          }}
        />
      </Grid>
      {watch('pessoa') === 'FISICA' && (
        <Grid item xs={12} sm={6}>
          <InputMask
            name="cpf"
            control={control}
            label="CPF"
            mask="999.999.999-99"
            onKeyUp={(e) => {
              const value = e?.target?.value;
              if (value?.length === 14) {
                const entidade = drops?.Entidade.find(
                  (f) => f?.documento == value?.replace(/[.-]/g, '')
                );
                setValue('cadastro_id', entidade?.value || null);
              } else {
                setValue('cadastro_id', null);
              }
            }}
          />
        </Grid>
      )}
      {watch('pessoa') === 'JURIDICA' && (
        <Grid item xs={12} sm={6}>
          <InputMask
            name="cnpj"
            control={control}
            label="CNPJ"
            mask="99.999.999/9999-99"
            onKeyUp={(e) => {
              const value = e?.target?.value;
              if (value?.length === 18) {
                const entidade = drops?.Entidade.find(
                  (f) => f?.documento == value?.replace(/[./-]/g, '')
                );
                setValue('cadastro_id', entidade?.value || null);
              } else {
                setValue('cadastro_id', null);
              }
            }}
          />
        </Grid>
      )}
      <Grid item xs={10}>
        <VirtualDrop
          name="cadastro_id"
          control={control}
          label="Cliente"
          options={drops?.Entidade}
          refresh="Entidade"
          disabled
        />
      </Grid>
      <Grid
        item
        xs={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <IconButton
          color="primary"
          size="small"
          onClick={() =>
            window.open(
              `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                'cadastro_id'
              )}`
            )
          }
          disabled={!Boolean(watch('cadastro_id'))}
        >
          <Visibility fontSize="small" />
        </IconButton>
        <IconButton
          color="primary"
          size="small"
          onClick={() =>
            window.open(
              `https://cadastros.eprom2.com.br/app/Entidades/Indicadores/${watch(
                'cadastro_id'
              )}`
            )
          }
          disabled={!Boolean(watch('cadastro_id'))}
        >
          <QueryStats fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Cliente;
