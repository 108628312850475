import React, { useContext, useState } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { toastWarning } from 'utils/toast';
import { findOnArray, summarizer } from 'utils/functions';
import { VendaContext } from 'contexts/VendaContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog } from 'components/Modals';
import TableContainer from 'components/TableContainer';
import Card from 'components/Card';
import Button from 'components/Button';
import moment from 'moment';

const ResumoModal = ({ itens, values, onReset, origem_id, mainType }) => {
  const { closeDialog } = useDialog();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postLoading, postVenda, contas } = useContext(VendaContext);
  const total_recebido = summarizer(values?.pagamento, 'valor') || 0;
  const pagamento_vista = values?.pagamento?.filter(
    (f) =>
      findOnArray(f?.forma_pagto_id, drops?.FormaDePagamento, 'indpag') ===
      '0-A VISTA'
  );
  const pagamento_prazo = values?.pagamento?.filter(
    (f) =>
      findOnArray(f?.forma_pagto_id, drops?.FormaDePagamento, 'indpag') ===
      '1-A PRAZO'
  );
  const troco =
    (summarizer(pagamento_vista, 'valor') || 0) -
    (summarizer(itens, 'subtotal') || 0);

  const getMainType = () => {
    switch (mainType) {
      case 'Fatura':
        return 0;
      case 'Cupom':
        return 1;
      case 'CFe':
        return 2;
      case 'NFSe':
        return 3;
      case 'Pedido':
        return 4;
      case 'NFe':
        return 5;
      case 'NFCe':
        return 6;

      default:
        return 1;
    }
  };
  const [type, setType] = useState(getMainType());

  const onSubmit = () => {
    let especie_id = null;
    let natureza_operacao_id = null;
    let modelofiscal_id = null;
    let serie_id = null;
    switch (type) {
      //FATURA
      case 0:
        especie_id = 25;
        natureza_operacao_id = 6;
        modelofiscal_id = 99;
        break;
      //Cupom
      case 1:
        especie_id = 43;
        natureza_operacao_id = 6;
        modelofiscal_id = 3;
        break;
      //CF-e
      case 2:
        especie_id = 26;
        natureza_operacao_id = 6;
        modelofiscal_id = 33;
        break;
      //NFS-e
      case 3:
        especie_id = 10;
        natureza_operacao_id = 11;
        modelofiscal_id = 100;
        break;
      //Pedido
      case 4:
        especie_id = 9;
        natureza_operacao_id = 6;
        modelofiscal_id = null;
        break;
      //NF-e
      case 5:
        especie_id = 10;
        natureza_operacao_id = 6;
        modelofiscal_id = 31;
        break;
      //NFC-e
      case 6:
        especie_id = 50;
        natureza_operacao_id = 6;
        modelofiscal_id = 37;
        break;

      default:
        break;
    }
    serie_id =
      type === 4
        ? null
        : type === 2
        ? drops?.Serie?.find(
            (f) =>
              f?.modelofiscal_id === modelofiscal_id &&
              f?.cxconta_id === contas?.cxconta_id
          )?.value || null
        : drops?.Serie?.find((f) => f?.modelofiscal_id === modelofiscal_id)
            ?.value || null;

    if (!Boolean(especie_id)) {
      return toastWarning('É necessário ajuste na configuração do sistema');
    }
    let data = {
      lancamento: {
        cxconta_id: contas?.cxconta_id,
        seqcaixa: contas?.seqcaixa,
        formas: values?.pagamento,
      },
      documento: {
        Documento: {
          especie_id,
          natureza_operacao_id,
          conferido: especie_id !== 9 ? 'SIM' : null,
          users_id: user?.id,
          filial_id: user?.filial_id,
          cadastro_id: values?.cadastro_id,
          dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
          dadosadc: values?.dadosadc,
          usofinal: 'SIM',
        },
        Saida: {
          serie_id,
          condicao_pagamento_id: values?.condicao_pagamento_id,
          vendedor_id: values?.vendedor_id,
          dtsaida: moment().format('YYYY-MM-DD HH:mm:ss'),
          documento:
            values?.pessoa === 'FISICA'
              ? values?.cpf
              : values?.pessoa === 'JURIDICA'
              ? values?.cnpj
              : null,
        },
        Itens: itens?.map((i, index) => {
          let item = {
            sequencia: index + 1,
            produto_id: i?.produto_id,
            referencia: i?.referencia,
            descricao: i?.descricao,
            quantidade: i?.quantidade,
            preco: i?.preco,
            precobase_id: i?.precobase_id,
            desconto1_pc: i?.desconto1_pc,
            desconto_vlr: i?.desconto_vlr,
            planoconta_id: contas?.planoconta_id,
          };
          if (Boolean(i?.origem_id)) {
            item = {
              ...item,
              Origens: [{ documento_item_org_id: i?.origem_id }],
            };
          }
          return item;
        }),
        Emitir: { tpamb: 1 },
      },
    };
    const Financeiros = pagamento_prazo?.map((p, i) => ({
      ...p,
      parcela: i + 1,
    }));

    if (Boolean(Financeiros?.length)) {
      data = { ...data, documento: { ...data?.documento, Financeiros } };
    }

    if (Boolean(troco > 0)) {
      data = {
        ...data,
        lancamento: {
          ...data?.lancamento,
          troco: parseFloat(troco?.toFixed(2)),
          plano_troco_id: contas?.plano_troco_id,
        },
      };
    }
    const iss = summarizer(itens, 'iss_ret') || 0;

    if (Boolean(iss !== 0)) {
      data = {
        ...data,
        lancamento: {
          ...data?.lancamento,
          iss: Math.abs(parseFloat(iss?.toFixed(2))),
          plano_iss_id: contas?.plano_iss_id,
        },
      };
    }

    if (Boolean(origem_id)) {
      data = {
        ...data,
        documento: {
          ...data?.documento,
          Origens: [{ documento_id: origem_id }],
        },
      };
    }
    if (type === 3 || type === 5 || type === 6) {
      data = {
        ...data,
        documento: {
          ...data?.documento,
          Transmitir: true,
        },
      };
    }
    if (type === 5 || type === 6) {
      data = {
        ...data,
        documento: {
          ...data?.documento,
          Transportes: [
            {
              tipotransp: 'DESPACHO',
              viatransp: '01 - RODOVIARIO',
              fretepc: '9 - SEM OCORRENCIA DE TRANSPORTE',
              natureza: '9 - OUTRAS',
            },
          ],
        },
      };
    }

    postVenda({
      data,
      cb: () => {
        closeDialog();
        onReset();
      },
    });
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div">
          Dados
        </Typography>
        <Typography variant="body1" component="div">
          Data: {moment().format('DD/MM/YYYY [às] HH:mm')}
        </Typography>
        {Boolean(values?.tabpreco_id) && (
          <Typography variant="body1" component="div">
            Tabela de Preço:{' '}
            {findOnArray(values?.tabpreco_id, drops?.TabelaPreco, 'label')}
          </Typography>
        )}
        {Boolean(values?.vendedor_id) && (
          <Typography variant="body1" component="div">
            Vendedor:{' '}
            {findOnArray(values?.vendedor_id, drops?.EntidadeTipo, 'label')}
          </Typography>
        )}
      </Grid>
      {(Boolean(values?.cadastro_id) ||
        Boolean(values?.cpf) ||
        Boolean(values?.cnpj)) && (
        <Grid item xs={12}>
          <Typography variant="h6" component="div">
            Cliente
          </Typography>
          <Typography variant="body1" component="div">
            {findOnArray(values?.cadastro_id, drops?.Entidade, 'label')}
          </Typography>
          {Boolean(values?.cpf) && (
            <Typography variant="body1" component="div">
              CPF: {values?.cpf}
            </Typography>
          )}
          {Boolean(values?.cnpj) && (
            <Typography variant="body1" component="div">
              CNPJ: {values?.cnpj}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h6" component="div">
          Itens
        </Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Produto</TableCell>
                <TableCell>Quantidade</TableCell>
                <TableCell>Preço Unitário</TableCell>
                <TableCell>Desconto</TableCell>
                <TableCell>Imposto</TableCell>
                <TableCell>Sub total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itens?.map((item, index) => (
                <TableRow key={index?.toString()}>
                  <TableCell>{item?.descricao}</TableCell>
                  <TableCell>{item?.quantidade}</TableCell>
                  <TableCell>
                    {item?.preco?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                  <TableCell>
                    {item?.desconto_vlr?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                  <TableCell>
                    {item?.iss_ret?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                  <TableCell>
                    {item?.subtotal?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="div">
          Pagamento
        </Typography>
        {Boolean(values?.pagamento?.length) && (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Parcela</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Forma de Pagamento</TableCell>
                  <TableCell>SKU</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values?.pagamento?.map((item, index) => (
                  <TableRow key={index?.toString()}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {Boolean(item?.dtvenc) &&
                        moment(item?.dtvenc).isValid() &&
                        moment(item?.dtvenc).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      {item?.valor?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </TableCell>
                    <TableCell>
                      {findOnArray(
                        item?.forma_pagto_id,
                        drops?.FormaDePagamento,
                        'label'
                      )}
                    </TableCell>
                    <TableCell>{item?.autenticacao}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={Boolean(troco > 0) ? 4 : 6}>
            <Card>
              <Typography variant="h6" color="primary" align="center">
                Total:{' '}
                {summarizer(itens, 'subtotal')?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={Boolean(troco > 0) ? 4 : 6}>
            <Card>
              <Typography variant="h6" color="primary" align="center">
                Total Recebido:{' '}
                {total_recebido?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
            </Card>
          </Grid>
          {Boolean(troco > 0) && (
            <Grid item xs={12} sm={4}>
              <Card>
                <Typography variant="h6" color="primary" align="center">
                  Troco:{' '}
                  {troco?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="div">
          Emissão
        </Typography>
        <Box display="flex" alignItems="center">
          <Box
            display={values?.cadastro_id ? 'flex' : 'none'}
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <Radio
              name="fatura"
              id="fatura"
              color="primary"
              size="small"
              checked={type === 0}
              onChange={() => setType(0)}
              disabled={Boolean(mainType)}
            />
            <Tooltip title="Emitir fatura" placement="top">
              <InputLabel htmlFor="fatura">Fatura</InputLabel>
            </Tooltip>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <Radio
              name="cupom"
              id="cupom"
              color="primary"
              size="small"
              checked={type === 1}
              onChange={() => setType(1)}
              disabled={Boolean(mainType)}
            />
            <Tooltip title="Emitir cupom" placement="top">
              <InputLabel htmlFor="cupom">Cupom</InputLabel>
            </Tooltip>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <Radio
              name="cfe"
              id="cfe"
              color="primary"
              size="small"
              checked={type === 2}
              onChange={() => setType(2)}
              disabled={Boolean(mainType)}
            />
            <Tooltip
              title="Emitir cupom fiscal eletrônico / SAT"
              placement="top"
            >
              <InputLabel htmlFor="cfe">CF-e</InputLabel>
            </Tooltip>
          </Box>
          <Box
            display={values?.cadastro_id ? 'flex' : 'none'}
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <Radio
              name="nfse"
              id="nfse"
              color="primary"
              size="small"
              checked={type === 3}
              onChange={() => setType(3)}
              disabled={Boolean(mainType)}
            />
            <Tooltip
              title="Emitir nota fiscal eletrônica de serviço"
              placement="top"
            >
              <InputLabel htmlFor="nfse">NFS-e</InputLabel>
            </Tooltip>
          </Box>
          <Box
            display={values?.cadastro_id ? 'flex' : 'none'}
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <Radio
              name="ne"
              id="ne"
              color="primary"
              size="small"
              checked={type === 4}
              onChange={() => setType(4)}
              disabled={Boolean(mainType)}
            />
            <Tooltip title="Gerar pedido de venda" placement="top">
              <InputLabel htmlFor="ne">Pedido</InputLabel>
            </Tooltip>
          </Box>
          <Box
            display={values?.cadastro_id ? 'flex' : 'none'}
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <Radio
              name="nfe"
              id="nfe"
              color="primary"
              size="small"
              checked={type === 5}
              onChange={() => setType(5)}
              disabled={Boolean(mainType)}
            />
            <Tooltip title="Emitir nota fiscal eletrônica" placement="top">
              <InputLabel htmlFor="nfe">NF-e</InputLabel>
            </Tooltip>
          </Box>
          <Box
            display={values?.cadastro_id ? 'flex' : 'none'}
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <Radio
              name="nfce"
              id="nfce"
              color="primary"
              size="small"
              checked={type === 6}
              onChange={() => setType(6)}
              disabled={Boolean(mainType)}
            />
            <Tooltip
              title="Emitir nota fiscal de consumidor eletrônica"
              placement="top"
            >
              <InputLabel htmlFor="nfce">NFC-e</InputLabel>
            </Tooltip>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          loading={postLoading}
        >
          Finalizar
        </Button>
      </Grid>
    </Grid>
  );
};
export default ResumoModal;
