import { useContext, useEffect, useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Pagination,
} from '@mui/material';
import {
  DataGrid,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { toastWarning } from 'utils/toast';
import { useModal } from 'components/Modals';
import Button from 'components/Button';
import localeText from 'utils/localeText';
import moment from 'moment';

const VinculoModal = ({ onSubmit, item, ...params }) => {
  const { closeModal } = useModal();
  const [vinculos, setVinculos] = useState([]);
  const [selected, setSelected] = useState([]);
  const { getLoading, getDocumentoVinculos } = useContext(DocumentosContext);

  useEffect(() => {
    getDocumentoVinculos({
      params,
      cb: (arr) =>
        setVinculos(arr?.filter((f) => f?.produto_id === item?.produto_id)),
    });
  }, []);

  const onConfirm = () => {
    const vinculo = selected?.find((f) => Boolean(f?.id));
    if (Boolean(vinculo)) {
      onSubmit({ vinculo, item });
    } else {
      toastWarning('Selecione ao menos um item');
    }
  };

  const CustomPagination = () => {
    const gridRef = useGridApiContext();
    const count = useGridSelector(gridRef, gridPageCountSelector);
    return (
      <Pagination
        color="primary"
        count={count}
        onChange={(_, value) => gridRef?.current?.setPage(value - 1)}
      />
    );
  };

  return (
    <>
      <DialogTitle>Vincular {item?.descricao}</DialogTitle>
      <DialogContent>
        <DataGrid
          rows={vinculos}
          columns={[
            {
              field: 'descricao',
              headerName: 'Produto',
              width: 400,
              sortable: false,
            },
            {
              field: 'quantidade',
              headerName: 'Quantidade',
              type: 'number',
              width: 150,
              sortable: false,
              valueGetter: ({ row }) =>
                (row?.quantidade || 0) + ' ' + (row?.unidade_medida || ''),
            },
            {
              field: 'natureza',
              headerName: 'Natureza',
              width: 400,
              sortable: false,
            },
            {
              field: 'documento',
              headerName: 'Nº do Documento',
              width: 150,
              sortable: false,
            },
            {
              field: 'dtemissao',
              headerName: 'Data de Emissão',
              width: 150,
              sortable: false,
              valueFormatter: ({ value }) =>
                value && moment(value).format('DD/MM/YYYY'),
            },
          ]}
          autoHeight
          pagination
          pageSize={10}
          density="compact"
          loading={getLoading}
          disableColumnMenu
          checkboxSelection
          showCellRightBorder
          showColumnRightBorder
          disableSelectionOnClick
          localeText={localeText}
          keepNonExistentRowsSelected
          selectionModel={selected?.map((s) => s?.id)}
          onSelectionModelChange={(ids) => {
            let arr = ids?.map((id) => vinculos?.find((f) => f?.id === id));
            if (Boolean(arr?.length > 1)) {
              arr = arr?.slice(1, 2);
            } else {
              arr = arr?.slice(0, 1);
            }
            setSelected(arr);
          }}
          components={{
            LoadingOverlay: LinearProgress,
            Pagination: CustomPagination,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary" variant="contained">
          Voltar
        </Button>
      </DialogActions>
    </>
  );
};

export default VinculoModal;
