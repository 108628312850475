import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDialog } from 'components/Modals';
import InputMask from 'components/InputMask';
import Button from 'components/Button';
import Input from 'components/Input';

const ItemModal = ({ user, item, index, setItens, itens }) => {
  const { closeDialog } = useDialog();
  const defaultValues = {
    quantidade: item?.quantidade || '',
    desconto1_pc: item?.desconto1_pc || '',
    desconto_vlr: item?.desconto_vlr || '',
    preco: item?.preco || '',
    infadc: item?.infadc || '',
  };
  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues,
  });

  useEffect(() => {
    const desconto_vlr =
      watch('desconto1_pc') * watch('preco') * watch('quantidade');
    setValue(
      'desconto_vlr',
      desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr
    );
  }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

  const onSubmit = (values) => {
    const subtotal =
      (watch('quantidade') || 0) * (watch('preco') || 0) +
      (watch('desconto_vlr') || 0);
    setItens(
      itens?.map((i, idx) =>
        index === idx ? { ...i, ...values, subtotal } : i
      )
    );
    closeDialog();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          {item?.descricao}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <InputMask name="quantidade" control={control} label="Quantidade" />
      </Grid>
      <Grid item xs={4}>
        <InputMask name="desconto1_pc" control={control} label="% Desconto" />
      </Grid>
      <Grid item xs={4}>
        <InputMask
          name="preco"
          control={control}
          label="Preço"
          disabled={user?.permissoes?.block_altera_precos}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="infadc"
          control={control}
          label="Informações Adicionais"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};
export default ItemModal;
