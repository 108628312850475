import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Icon,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Delete, Edit, QueryStats, Visibility } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { toastWarning } from 'utils/toast';
import { dropTipoPessoa } from 'utils/drops';
import {
  summarizer,
  getDatePagto,
  findOnArray,
  decryptURL,
} from 'utils/functions';
import { RemessasContext } from 'contexts/RemessasContext';
import { DropsContext } from 'contexts/DropsContext';
import { useDialog } from 'components/Modals';
import TableContainer from 'components/TableContainer';
import VirtualDrop from 'components/VirtualDrop';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';
import styles from './styles';
import ResumoModal from './Modals/ResumoModal';
import PagamentoModal from './Modals/PagamentoModal';
import moment from 'moment';
import ItemModal from './Modals/ItemModal';

const Venda = () => {
  const navigate = useNavigate();
  const { hash } = useParams();
  const [step, setStep] = useState(0);
  const decrypt = decryptURL(hash);
  const defaultValues = {
    //CLIENTE
    pessoa: 'FISICA',
    cadastro_id: null,
    cnpj: '',
    cpf: '',
    //ITENS
    itens: [],
    //PAGAMENTO
    forma_pagto_id: null,
    condicao_pagamento_id: null,
    fixar: false,
    valor: '',
    pagamento: [],
  };
  const { control, setValue, getValues, watch, handleSubmit } = useForm({
    defaultValues,
  });
  const { drops } = useContext(DropsContext);
  const { getItens, getLoading } = useContext(RemessasContext);
  const { openDialog, closeDialog } = useDialog();

  useEffect(() => {
    if (step === 0) {
      setValue('itens', []);
    }
    if (step !== 2) {
      setValue('pagamento', []);
    }
  }, [step]);

  useEffect(() => {
    if (decrypt?.cadastro_id) {
      setValue('cadastro_id', decrypt?.cadastro_id);
      const documento = findOnArray(
        decrypt?.cadastro_id,
        drops?.Entidade,
        'documento'
      );
      searchDocumento(documento);
    }
  }, []);

  useEffect(() => {
    const values = getValues();
    const total =
      (summarizer(values?.itens, 'subtotal') || 0) -
      (summarizer(values?.pagamento, 'valor') || 0);
    setValue('valor', total > 0 ? Number(total?.toFixed(2)) : '');
  }, [watch('itens'), watch('pagamento')]);

  const onCellEditCommit = (e) => {
    const editedRows = watch('itens')?.map((c) => {
      let newItem = { ...c };

      if (c?.id === e?.id && e?.field === 'quantidade') {
        if (e?.value > c?.quantidade_max) {
          newItem = { ...c, [e?.field]: c?.quantidade_max };
        } else {
          newItem = { ...c, [e?.field]: e?.value };
        }
      }

      const desconto_vlr =
        newItem?.desconto1_pc * newItem?.preco * newItem?.quantidade;

      newItem = {
        ...newItem,
        desconto_vlr: desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr,
      };
      const subtotal =
        (newItem?.preco || 0) * (newItem?.quantidade || 0) +
        (newItem?.desconto_vlr || 0);
      newItem = { ...newItem, subtotal };
      return newItem;
    });
    setValue('itens', editedRows);
  };

  const onEditItem = ({ id, ...rest }) => {
    const { itens } = getValues();
    const preco = rest?.preco || 0;
    const quantidade = rest?.quantidade || 0;
    const desconto_vlr = rest?.desconto_vlr || 0;
    const desconto1_pc = rest?.desconto1_pc || 0;
    const quantidade_max = rest?.quantidade_max || 0;
    if (quantidade > quantidade_max) {
      return toastWarning(
        `Quantidade limite excedida. (Limite: ${quantidade_max})`
      );
    }
    if (quantidade <= 0) {
      onDeleteItem(id);
    } else {
      setValue(
        'itens',
        itens?.map((item) => {
          if (item?.id === id) {
            const subtotal = quantidade * preco + desconto_vlr;
            return {
              ...item,
              preco,
              quantidade,
              desconto_vlr,
              desconto1_pc,
              subtotal,
            };
          }
          return item;
        })
      );
    }
    closeDialog();
  };

  const onDeleteItem = (id) => {
    const itens = watch('itens')?.filter((i) => i?.id !== id);
    setValue('itens', itens);
  };

  const onAddPagto = (values) => {
    const valor_total = values?.valor;
    const forma_pagto_id = values?.forma_pagto_id;
    const condicao_pagamento_id = values?.condicao_pagamento_id;
    const fixar = values?.fixar;
    if (!valor_total) {
      return toastWarning('Valor não informado');
    } else if (!Boolean(forma_pagto_id)) {
      return toastWarning('Forma de pagamento não informada');
    } else if (!Boolean(condicao_pagamento_id)) {
      return toastWarning('Condição de pagamento não informada');
    } else {
      const forma = findOnArray(forma_pagto_id, drops?.FormaDePagamento);
      const condicoes = forma?.condicoes || [];
      const cond_pagto = findOnArray(condicao_pagamento_id, condicoes);
      const valor_total = summarizer(values?.itens, 'subtotal');

      const pagamento = [];
      let valor_lancado = 0;
      for (let parcela = 1; parcela <= cond_pagto?.nvezes; parcela++) {
        const valor = parseFloat((valor_total / cond_pagto?.nvezes).toFixed(2));
        pagamento.push({
          parcela,
          dtvenc: getDatePagto({ ...cond_pagto, parcela, fixar }),
          valor:
            parcela === cond_pagto?.nvezes
              ? valor_total - valor_lancado
              : valor,
          forma_pagto: forma?.label,
          forma_pagto_id,
        });
        valor_lancado += valor;
      }
      setValue('pagamento', pagamento);
    }
  };

  const onEditPagto = ({ index, ...rest }) => {
    const { pagamento } = getValues();
    const forma_pagto = findOnArray(
      rest?.forma_pagto_id,
      drops?.FormaDePagamento,
      'label'
    );
    setValue(
      'pagamento',
      pagamento?.map((p, i) => {
        if (i === index) {
          return { ...rest, forma_pagto };
        }
        return p;
      })
    );
    closeDialog();
  };

  const onDeletePagto = (index) => {
    const pagamento = watch('pagamento')?.filter((_, i) => index !== i);
    setValue('pagamento', pagamento);
  };

  const onChangeStep = (values) => {
    getItens({
      values: { itens: decrypt?.itens, cadastro_id: values?.cadastro_id },
      cb: (data) => {
        const itens = data
          ?.map((item) => {
            const gerados = item?.Vinculos?.filter(
              (v) =>
                v?.natureza_operacao_id === 54 ||
                ((v?.especie === 'FATURA' || v?.especie === 'ESTOQUE') &&
                  v?.terceiro === 'SIM')
            );
            const quantidade =
              (item?.quantidade || 0) - summarizer(gerados, 'quantidade');
            item['quantidade'] = quantidade;
            item['quantidade_max'] = quantidade;
            item['subtotal'] =
              (item?.quantidade || 0) * (item?.preco || 0) +
              (item?.desconto_vlr || 0);
            delete item?.Vinculos;
            return item;
          })
          ?.filter((f) => Boolean(f?.quantidade));
        setValue('itens', itens);
        setStep(1);
      },
    });
  };

  const onSubmit = (values) => {
    if (!Boolean(values?.itens?.length)) {
      return toastWarning('Insira ao menos um item');
    }
    if (!Boolean(values?.pagamento?.length)) {
      return toastWarning('Insira uma Forma de Pagamento');
    }
    openDialog(
      <ResumoModal
        values={values}
        vendedor_id={decrypt?.vendedor_id}
        onReset={() => {
          closeDialog();
          navigate(-1);
        }}
      />,
      'Resumo da Venda'
    );
  };

  const searchCliente = ({ value, size }) => {
    if (value?.length === size) {
      const entidade = drops?.Entidade.find(
        (f) => f?.documento == value?.replace(/[./-]/g, '')
      );
      setValue('cadastro_id', entidade?.value || null);
    } else {
      setValue('cadastro_id', null);
    }
  };

  const searchDocumento = (numero) => {
    let documento = numero?.replace(/\D/g, '');
    if (documento?.length === 11) {
      documento = documento
        ?.replace(/(\d{3})(\d)/, '$1.$2')
        ?.replace(/(\d{3})(\d)/, '$1.$2')
        ?.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      setValue('pessoa', 'FISICA');
      setValue('cpf', documento);
      setValue('cnpj', '');
    } else if (documento?.length === 14) {
      documento = documento
        ?.replace(/^(\d{2})(\d)/, '$1.$2')
        ?.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        ?.replace(/\.(\d{3})(\d)/, '.$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2');
      setValue('pessoa', 'JURIDICA');
      setValue('cnpj', documento);
      setValue('cpf', '');
    }
  };

  return (
    <Container>
      <Header titulo="Venda" />
      <Card>
        <Stepper activeStep={step} sx={styles?.stepper}>
          <Step>
            <StepLabel>Cliente</StepLabel>
          </Step>
          <Step>
            <StepLabel>Itens</StepLabel>
          </Step>
          <Step>
            <StepLabel>Pagamento</StepLabel>
          </Step>
        </Stepper>
        {step === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="pessoa"
                control={control}
                label="Tipo de Pessoa"
                options={dropTipoPessoa}
                onValueChange={() => {
                  setValue('cpf', '');
                  setValue('cnpj', '');
                  setValue('cadastro_id', null);
                }}
              />
            </Grid>
            {watch('pessoa') === 'FISICA' && (
              <Grid item xs={12} sm={6}>
                <InputMask
                  name="cpf"
                  control={control}
                  label="CPF"
                  mask="999.999.999-99"
                  onKeyUp={(e) => {
                    const value = e?.target?.value;
                    searchCliente({ value, size: 14 });
                  }}
                />
              </Grid>
            )}
            {watch('pessoa') === 'JURIDICA' && (
              <Grid item xs={12} sm={6}>
                <InputMask
                  name="cnpj"
                  control={control}
                  label="CNPJ"
                  mask="99.999.999/9999-99"
                  onKeyUp={(e) => {
                    const value = e?.target?.value;
                    searchCliente({ value, size: 18 });
                  }}
                />
              </Grid>
            )}
            <Grid item xs={10}>
              <VirtualDrop
                name="cadastro_id"
                control={control}
                label="Cliente"
                options={drops?.Entidade}
                refresh="Entidade"
                onValueChange={(v) => searchDocumento(v?.documento)}
              />
            </Grid>
            <Grid
              item
              xs={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <IconButton
                color="primary"
                size="small"
                onClick={() =>
                  window.open(
                    `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                      'cadastro_id'
                    )}`
                  )
                }
                disabled={!Boolean(watch('cadastro_id'))}
              >
                <Visibility fontSize="small" />
              </IconButton>
              <IconButton
                color="primary"
                size="small"
                onClick={() =>
                  window.open(
                    `https://cadastros.eprom2.com.br/app/Entidades/Indicadores/${watch(
                      'cadastro_id'
                    )}`
                  )
                }
                disabled={!Boolean(watch('cadastro_id'))}
              >
                <QueryStats fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item xs={12} {...styles?.buttonContainer}>
              <Button
                variant="outlined"
                color="primary"
                sx={styles?.button}
                loading={getLoading}
                onClick={handleSubmit(onChangeStep)}
              >
                PRÓXIMO
              </Button>
            </Grid>
          </Grid>
        )}
        {step === 1 && getLoading && <Loader />}
        {step === 1 && !getLoading && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DataGrid
                rows={watch('itens')}
                columns={[
                  {
                    field: 'referencia',
                    headerName: 'Referência',
                    width: 200,
                    sortable: false,
                  },
                  {
                    field: 'descricao',
                    headerName: 'Produto',
                    width: 440,
                    sortable: false,
                  },
                  {
                    field: 'quantidade',
                    headerName: 'Quantidade',
                    width: 200,
                    type: 'number',
                    sortable: false,
                    editable: true,
                  },
                  {
                    field: 'preco',
                    headerName: 'Preço Unitário',
                    type: 'number',
                    valueGetter: ({ value }) =>
                      (value || 0)?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      }),
                    width: 200,
                    sortable: false,
                  },
                  {
                    field: 'valor',
                    headerName: 'Valor',
                    type: 'number',
                    valueGetter: ({ row }) =>
                      (
                        (row?.quantidade || 0) * (row?.preco || 0)
                      )?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      }),
                    width: 200,
                    sortable: false,
                  },
                  {
                    field: 'desconto_vlr',
                    headerName: 'Desconto',
                    type: 'number',
                    valueGetter: ({ value }) =>
                      (value || 0)?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      }),
                    width: 200,
                    sortable: false,
                  },

                  {
                    field: 'subtotal',
                    headerName: 'Subtotal',
                    type: 'number',
                    valueGetter: ({ value }) =>
                      (value || 0)?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      }),
                    width: 200,
                    sortable: false,
                  },
                  {
                    field: 'actions',
                    headerName: 'Ações',
                    type: 'actions',
                    width: 200,

                    getActions: ({ id, row }) => [
                      <GridActionsCellItem
                        icon={<Icon>delete</Icon>}
                        label="Excluir"
                        onClick={() => onDeleteItem(id)}
                      />,
                      <GridActionsCellItem
                        icon={<Icon>edit</Icon>}
                        label="Editar"
                        onClick={() =>
                          openDialog(
                            <ItemModal item={row} onSubmit={onEditItem} />,
                            'Editar Item'
                          )
                        }
                      />,
                    ],
                  },
                ]}
                hideFooter
                autoHeight
                density="compact"
                disableSelectionOnClick
                disableColumnMenu
                showCellRightBorder
                showColumnRightBorder
                onCellEditCommit={onCellEditCommit}
                localeText={{ noRowsLabel: 'Nenhum Registro.' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary" align="center">
                Total:{' '}
                {summarizer(watch('itens'), 'subtotal')?.toLocaleString(
                  'pt-br',
                  {
                    style: 'currency',
                    currency: 'BRL',
                  }
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} {...styles?.buttonContainer}>
              <Button
                variant="outlined"
                color="secondary"
                sx={styles?.button}
                onClick={() => setStep(0)}
              >
                VOLTAR
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={styles?.button}
                onClick={() => setStep(2)}
              >
                PRÓXIMO
              </Button>
            </Grid>
          </Grid>
        )}
        {step === 2 && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Dropdown
                name="forma_pagto_id"
                control={control}
                label="Forma de Pagamento"
                onValueChange={() => setValue('condicao_pagamento_id', null)}
                options={drops?.FormaDePagamento?.filter(
                  (f) =>
                    f?.caixa === 'SIM' &&
                    f?.modulo !== 'COMPRAS' &&
                    f?.modulo !== 'CHEQUES'
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                name="condicao_pagamento_id"
                control={control}
                label="Condição de Pagamento"
                options={findOnArray(
                  watch('forma_pagto_id'),
                  drops?.FormaDePagamento,
                  'condicoes'
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask name="valor" control={control} label="Valor" />
            </Grid>
            <Grid item xs={12}>
              <Checkbox name="fixar" control={control} label="Fixar Dia" />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSubmit(onAddPagto)}
              >
                CALCULAR
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Parcela</TableCell>
                      <TableCell>Data de Vencimento</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell>Forma de Pagamento</TableCell>
                      <TableCell align="center">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {watch('pagamento')?.map((item, index) => (
                      <TableRow key={index?.toString()}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {Boolean(item?.dtvenc) &&
                            moment(item?.dtvenc).isValid() &&
                            moment(item?.dtvenc).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>
                          {item?.valor?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </TableCell>
                        <TableCell>{item?.forma_pagto}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <PagamentoModal
                                  item={item}
                                  index={index}
                                  onSubmit={onEditPagto}
                                />,
                                'Editar Parcela'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => onDeletePagto(index)}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary" align="center">
                Total:{' '}
                {summarizer(watch('pagamento'), 'valor')?.toLocaleString(
                  'pt-br',
                  { style: 'currency', currency: 'BRL' }
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} {...styles?.buttonContainer}>
              <Button
                variant="outlined"
                color="secondary"
                sx={styles?.button}
                onClick={() => setStep(1)}
              >
                VOLTAR
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={styles?.button}
                onClick={handleSubmit(onSubmit)}
              >
                FINALIZAR
              </Button>
            </Grid>
          </Grid>
        )}
      </Card>
    </Container>
  );
};

export default Venda;
