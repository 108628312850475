import { createContext, useState } from 'react';
import api from 'services/api';

export const RemessasContext = createContext();

export const RemessasProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  const postRemessa = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Comercial/Remessas', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postRemessaCopia = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Comercial/Remessas/Copia', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const getItens = async ({ values, cb }) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Comercial/Remessas/Itens', values);
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <RemessasContext.Provider
      value={{
        getLoading,
        postRemessa,
        postLoading,
        postRemessaCopia,
        getItens,
      }}
    >
      {children}
    </RemessasContext.Provider>
  );
};
