import { useContext, useEffect, useState } from 'react';
import { Grid, LinearProgress, Pagination, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import { useDialog } from 'components/Modals';
import localeText from 'utils/localeText';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';
import api from 'services/api';

const ImportModal = ({ onSubmit }) => {
  const defaultValues = {
    documento: '',
    ccusto: [],
    page: 0,
    size: 10,
    count: 0,
  };
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const { closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });

  const loadData = async (values) => {
    try {
      setLoading(true);
      const { data } = await api.post(`/Comercial/Remessas/Estrutura`, values);
      setRows(data?.rows || []);
      setValue('count', data?.count || 0);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onEditItem = ({ id, field, value }) => {
    setRows((prev) =>
      prev?.map((p) => (p?.id === id ? { ...p, [field]: value || 0 } : p))
    );
    setSelected((prev) =>
      prev?.map((p) => (p?.id === id ? { ...p, [field]: value || 0 } : p))
    );
  };

  const onImport = () => {
    const arr = selected?.map((s) => ({
      subtotal: parseFloat(
        ((s?.preco || 0) * (s?.quantidade || 0))?.toFixed(2)
      ),
      produto_id: s?.componente_id,
      descricao: s?.componente,
      quantidade: s?.quantidade,
      unidade_medida_id: s?.unidade_medida_id,
      referencia: s?.referencia,
      preco: s?.preco,
    }));
    onSubmit(arr);
    closeDialog();
  };

  useEffect(() => {
    const cond = Boolean(watch('documento')) || Boolean(watch('ccusto'));
    const delayDebounceFn = setTimeout(() => {
      setValue('page', 0);
      handleSubmit(loadData)();
    }, 2000);
    if (cond) setLoading(true);
    return () => clearTimeout(delayDebounceFn);
  }, [watch('documento'), watch('ccusto')]);

  const CustomPagination = () => (
    <Pagination
      color="primary"
      count={Math.ceil(watch('count') / watch('size'))}
      page={watch('page') + 1}
      onChange={(_, page) => {
        setValue('page', page - 1);
        handleSubmit(loadData)();
      }}
    />
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold">
          Filtros
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Dropdown
          name="ccusto"
          control={control}
          label="Centro de Custo"
          options={drops?.Ccusto}
          multiple
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input name="documento" control={control} label="Documento" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold">
          Itens
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={rows}
          columns={[
            {
              field: 'documento',
              headerName: 'Nº do Documento',
              width: 150,
              sortable: false,
            },
            {
              field: 'componente',
              headerName: 'Componente',
              flex: 1,
              sortable: false,
            },
            {
              field: 'conjunto',
              headerName: 'Conjunto',
              flex: 1,
              sortable: false,
            },
            {
              field: 'processo',
              headerName: 'Processo',
              width: 200,
              sortable: false,
            },
            {
              field: 'ccusto',
              headerName: 'Centro de Custo',
              width: 300,
              sortable: false,
            },
            {
              field: 'quantidade',
              headerName: 'Quantidade',
              type: 'number',
              width: 150,
              sortable: false,
              editable: true,
              renderCell: ({ row }) => (
                <Typography variant="body2">
                  {row?.quantidade || 0} {row?.um || ''}
                </Typography>
              ),
            },
          ]}
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          checkboxSelection
          keepNonExistentRowsSelected
          loading={loading}
          selectionModel={selected?.map((s) => s?.id)}
          onSelectionModelChange={(ids) =>
            setSelected(ids?.map((id) => rows?.find((f) => f?.id === id)))
          }
          getRowHeight={() => 'auto'}
          localeText={localeText}
          rowCount={watch('count') || 0}
          pageSize={watch('size') || 10}
          pagination
          paginationMode="server"
          onCellEditCommit={onEditItem}
          components={{
            LoadingOverlay: LinearProgress,
            Pagination: CustomPagination,
          }}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button variant="contained" color="primary" onClick={onImport}>
          Importar
        </Button>
      </Grid>
    </Grid>
  );
};
export default ImportModal;
