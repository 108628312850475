const styles = {
  stepper: {
    pb: '4em',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  button: {
    mt: '2em',
  },
};

export default styles;
