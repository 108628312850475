import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import {
  AccountBalance,
  Description,
  Equalizer,
  Extension,
  Link,
  LocalAtm,
  PrecisionManufacturing,
  Timeline,
} from '@mui/icons-material';
import { AuthContext } from 'contexts/AuthContext';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useModal, useDialog } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import CustomTabs from './Tabs';

const Item = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [tab, setTab] = useState(0);
  const { openModal, closeModal } = useModal();
  const { closeDialog } = useDialog();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { deleteGrid } = useContext(GridContext);
  const {
    documentoItem,
    getLoading,
    getDocumentoItem,
    postLoading,
    postDocumento,
    postDocumentoItemEstrutura,
    postDocumentoItemProcesso,
    postDocumentoItemImposto,
    deleteLoading,
    deleteDocumentoItemVinculo,
  } = useContext(DocumentosContext);

  useEffect(() => {
    if (state?.item) {
      getDocumentoItem(state?.item?.id);
    } else {
      navigate(-1);
    }
  }, [state]);

  const onSubmit = (data) =>
    postDocumento({
      data,
      cb: () => {
        getDocumentoItem(state?.item?.id);
        closeDialog();
      },
    });

  const onDelete = (params) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            params,
            cb: () => {
              getDocumentoItem(state?.item?.id);
              closeModal();
            },
          })
        }
      />
    );

  const onSubmitEstrutura = (data) =>
    postDocumentoItemEstrutura({
      data,
      cb: () => getDocumentoItem(state?.item?.id),
    });

  const onSubmitProcesso = (data) =>
    postDocumentoItemProcesso({
      data,
      cb: () => getDocumentoItem(state?.item?.id),
    });

  const onSubmitImposto = (data) =>
    postDocumentoItemImposto({
      data,
      cb: () => getDocumentoItem(state?.item?.id),
    });

  const onDeleteVinculo = (data) => {
    const params = {
      documento_item_id: data?.documento_item_id,
      documento_item_id_vinculo: data?.documento_item_id_vinculo,
    };
    deleteDocumentoItemVinculo({
      params,
      cb: () => getDocumentoItem(state?.item?.id),
    });
  };

  const tabs = [
    {
      value: 0,
      key: 'Item',
      label: 'Item',
      icon: <Description />,
    },
    {
      value: 1,
      key: 'Estrutura',
      label: 'Estrutura',
      icon: <Extension />,
    },
    {
      value: 2,
      key: 'Processos',
      label: 'Processos',
      icon: <PrecisionManufacturing />,
    },
    {
      value: 3,
      key: 'Tributos',
      label: 'Tributos',
      icon: <LocalAtm />,
    },
    {
      value: 4,
      key: 'Margem de Contribuição',
      label: 'Margem de Contribuição',
      icon: <AccountBalance />,
    },
    {
      value: 5,
      key: 'Vínculos',
      label: 'Vínculos',
      icon: <Link />,
    },
    {
      value: 6,
      key: 'Variacao',
      label: 'Variações',
      icon: <Equalizer />,
    },
    {
      value: 7,
      key: 'Movimentacoes',
      label: 'Movimentações',
      icon: <Timeline />,
    },
  ];

  const options = [];

  return (
    <Container>
      <Header
        titulo={`Item: ${documentoItem?.descricao || ''}`}
        outros={options}
      />
      <Card>
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={(_, v) => setTab(v)}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
            },
          }}
        >
          {tabs.map(({ show = true, ...t }) => show && <Tab {...t} />)}
        </Tabs>
      </Card>
      <CustomTabs
        tab={tab}
        user={user}
        drops={drops}
        documentoItem={documentoItem}
        getLoading={getLoading}
        disableActions={state?.disableActions}
        loading={postLoading || deleteLoading}
        onDelete={onDelete}
        onSubmit={onSubmit}
        onSubmitEstrutura={onSubmitEstrutura}
        onSubmitProcesso={onSubmitProcesso}
        onSubmitImposto={onSubmitImposto}
        onDeleteVinculo={onDeleteVinculo}
      />
    </Container>
  );
};

export default Item;
