import React from 'react';
import Loader from 'components/Loader';
import Documento from './Documento';
import Itens from './Itens';
import Financeiros from './Financeiros';
import Transportes from './Transportes';
import MDFE from './MDFE';
import Registros from './Registros';
import FormaDeEnvio from './FormaDeEnvio';
import Transmissão from './Transmissão';
import Cartas from './Cartas';

const Tabs = ({ tab, getLoading, ...props }) => {
  if (getLoading) {
    return <Loader />;
  }
  switch (tab) {
    case 0:
      return <Documento {...props} />;
    case 1:
      return <Itens {...props} />;
    case 2:
      return <Financeiros {...props} />;
    case 3:
      return <Transportes {...props} />;
    case 4:
      return <MDFE {...props} />;
    case 5:
      return <Registros {...props} />;
    case 6:
      return <FormaDeEnvio {...props} />;
    case 7:
      return <Transmissão {...props} />;
    case 8:
      return <Cartas {...props} />;
    default:
      return null;
  }
};

export default Tabs;
