import React, { useContext } from 'react';
import { DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { useModal } from 'components/Modals';
import { useForm } from 'react-hook-form';
import { VendaContext } from 'contexts/VendaContext';
import InputMask from 'components/InputMask';
import Button from 'components/Button';
import moment from 'moment';

const LançamentoModal = ({ title }) => {
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues: { valor: '' } });
  const { postLancamento, postLoading, contas } = useContext(VendaContext);

  const onSubmit = (values) => {
    const data = [
      {
        conta_id: contas?.cxconta_id,
        seqcaixa: contas?.seqcaixa,
        dtemis: moment().format('YYYY-MM-DD'),
        dtlanc: moment().format('YYYY-MM-DD'),
        dtlanc_org: moment().format('YYYY-MM-DD'),
        conciliado: 'NAO',
        cxlancto_det: [
          {
            valor: values?.valor,
            historico:
              title === 'Sangria'
                ? contas?.plano_sangria
                : title === 'Suprimentos'
                ? contas?.plano_suprimento
                : null,
            planoconta_id:
              title === 'Sangria'
                ? contas?.plano_sangria_id
                : title === 'Suprimentos'
                ? contas?.plano_suprimento_id
                : null,
          },
        ],
      },
    ];
    postLancamento({
      data,
      cb: () => closeModal(),
    });
  };

  return (
    <>
      <DialogTitle>{title || 'Lançamento'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <InputMask name="valor" control={control} label="Valor" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)} loading={postLoading}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default LançamentoModal;
