import React, { useContext, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { dropTipoEmitente, dropTipoTransportador } from 'utils/drops';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import VirtualDrop from 'components/VirtualDrop';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import styles from './styles';
import moment from 'moment';

const Gerar = () => {
  const { state } = useLocation();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postDocumento, postLoading } = useContext(DocumentosContext);
  const defaultValues = {
    filial_id: user?.filial_id,
    cadastro_id: null,
    tpemit: null,
    entidade_id: null,
    tptransp: null,
    motorista_id: null,
    frota_id: null,
    observacao: '',
  };
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({ defaultValues });

  useEffect(() => {
    if (!Boolean(state?.documentos?.length)) {
      navigate(-1);
    }
  }, []);

  const onSubmit = (values) => {
    const data = {
      Documento: {
        especie_id: 12,
        natureza_operacao_id: 11,
        users_id: user?.id,
        filial_id: values?.filial_id,
        cadastro_id: values?.cadastro_id,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
        observacao: values?.observacao,
      },
      MDFe: {
        frota_id: values?.frota_id,
        tpemit: values?.tpemit,
        tptransp: values?.tptransp,
        motorista_id: values?.motorista_id,
        emissao: moment().format('YYYY-MM-DD HH:mm:ss'),
        Documentos: state?.documentos?.map((d) => ({ documento_id: d?.id })),
      },
      Transportes: [
        {
          tipotransp: 'DESPACHO',
          viatransp: '01 - RODOVIARIO',
          fretepc: '0 - CONTRATACAO DO FRETE POR CONTA DO REMETENTE (CIF)',
          natureza: '0 - OP.VENDAS COM ONUS SUPORTADO PELO ESTAB.VENDEDOR',
          entidade_id: values?.entidade_id,
        },
      ],
    };
    postDocumento({ data, cb: () => navigate(-1) });
  };

  return (
    <Container>
      <Header titulo="Gerar MDFE" />
      <Card title="Informações do Carregamento">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <VirtualDrop
              name="cadastro_id"
              control={control}
              label="Emitente"
              options={drops?.Entidade}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="tpemit"
              control={control}
              label="Tipo de Emitente"
              options={dropTipoEmitente}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Dropdown
              name="entidade_id"
              control={control}
              label="Transportador"
              options={drops?.EntidadeTipo?.filter(
                (t) => t?.tipo === 'TRANSPORTADOR'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="tptransp"
              control={control}
              label="Tipo de Transportador"
              options={dropTipoTransportador}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Dropdown
              name="motorista_id"
              control={control}
              label="Motorista"
              options={drops?.EntidadeTipo?.filter(
                (t) => t?.tipo === 'MOTORISTA'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="frota_id"
              control={control}
              label="Frota"
              options={drops?.Frota}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Box textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Box>
    </Container>
  );
};

export default Gerar;
