import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { DialogContent, Grid, Typography } from '@mui/material';
import { toastWarning } from 'utils/toast';
import { ContratosContext } from 'contexts/ContratosContext';
import Input from 'components/Input';
import Button from 'components/Button';
import moment from 'moment';

const ReferenciaModal = ({ onSubmit }) => {
  const defaultValues = {
    mes: moment().format('M') || '',
    ano: moment().format('YYYY') || '',
  };
  const { control, handleSubmit } = useForm({ defaultValues });
  const { postLoading } = useContext(ContratosContext);

  const onConfirm = (values) => {
    const ano = values?.ano;
    const mes = ('00' + values?.mes).slice(-2);
    let dtref = `${ano}-${mes}-01`;
    if (moment(dtref)?.isValid()) {
      dtref = moment(dtref).endOf('month').format('YYYY-MM-DD');
      onSubmit(dtref);
    } else {
      return toastWarning('Data inválida');
    }
  };

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Informe o mês/ano referente à ordem de serviço do(s) contrato(s)
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="mes" label="Mês" control={control} type="number" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="ano" label="Ano" control={control} type="number" />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            onClick={handleSubmit(onConfirm)}
            loading={postLoading}
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  );
};
export default ReferenciaModal;
