import { Box, Chip, Typography } from '@mui/material';
import moment from 'moment';

const StatusCell = ({ row = {} }) => {
  const color = {
    PENDENTE: 'warning',
    CONCLUIDO: 'success',
    CANCELADO: 'error',
  };
  return (
    <Box flex={1} textAlign="center">
      <Chip label={row?.status} color={color[row?.status]} size="small" />
    </Box>
  );
};

const DocumentoCell = ({ row = {} }) => {
  const lines = [
    {
      label: 'Data de Emissão',
      value:
        moment(row?.dtemissao).isValid() &&
        moment(row?.dtemissao).format('DD/MM/YYYY [às] HH:mm'),
    },
    {
      label: 'Emitido por',
      value: row?.user,
    },
    {
      label: 'Cliente',
      value: row?.cliente,
    },
    {
      label: 'Filial',
      value: row?.filial,
    },
    {
      label: 'Vendedor',
      value: row?.vendedor,
    },
    {
      label: 'Código do Fabricante',
      value: row?.fabricante,
    },
  ];
  return (
    <Box flex={1}>
      <Typography fontWeight="bold" mb={1} variant="body1">
        {row?.documento}
      </Typography>
      {lines?.map(
        ({ label, value }, i) =>
          Boolean(value) && (
            <Box key={i?.toString()} display="flex">
              <Typography flex={1} variant="caption" fontWeight="bold">
                {label}:{' '}
              </Typography>
              <Typography flex={2} variant="caption">
                {value}
              </Typography>
            </Box>
          )
      )}
    </Box>
  );
};

const ProdutoCell = ({ row = {} }) => {
  const lines = [
    {
      label: 'Marca',
      value: row?.marca,
    },
    {
      label: 'Quantidade',
      value: (row?.quantidade || 0) + ' ' + (row?.unidade_medida || ''),
    },
    {
      label: 'Preço Unitário',
      value: (row?.preco || 0)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Subtotal',
      value: ((row?.quantidade || 0) * (row?.preco || 0))?.toLocaleString(
        'pt-br',
        { style: 'currency', currency: 'BRL' }
      ),
    },
  ];
  return (
    <Box flex={1}>
      <Typography fontWeight="bold" mb={1} variant="body1">
        {row?.produto}
      </Typography>
      {lines?.map(
        ({ label, value }, i) =>
          Boolean(value) && (
            <Box key={i?.toString()} display="flex">
              <Typography flex={1} variant="caption" fontWeight="bold">
                {label}:{' '}
              </Typography>
              <Typography flex={2} variant="caption">
                {value}
              </Typography>
            </Box>
          )
      )}
    </Box>
  );
};

const VinculosCell = ({ row = {} }) => {
  const lines = [
    {
      label: 'Origem',
      value: row?.origem,
    },
    {
      label: 'Envio p/ Fornecedor',
      value: row?.remessa || row?.devolucao,
    },
    {
      label: 'Retorno do Fornecedor',
      value: row?.retorno,
    },
  ];
  return (
    <Box flex={1}>
      {lines?.map(
        ({ label, value }, i) =>
          Boolean(value) && (
            <Box key={i?.toString()} display="flex">
              <Typography flex={1} variant="caption" fontWeight="bold">
                {label}:{' '}
              </Typography>
              <Typography flex={2} variant="caption">
                {value}
              </Typography>
            </Box>
          )
      )}
    </Box>
  );
};

const mock = [
  {
    field: 'status',
    renderCell: (props) => <StatusCell {...props} />,
  },
  {
    field: 'documento',
    renderCell: (props) => <DocumentoCell {...props} />,
  },
  {
    field: 'produto',
    renderCell: (props) => <ProdutoCell {...props} />,
  },
  {
    field: 'vinculos',
    renderCell: (props) => <VinculosCell {...props} />,
  },
];

export default mock;
