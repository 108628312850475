export const dropBoolean = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

export const dropTipoPessoa = [
  { value: 'FISICA', label: 'FÍSICA' },
  { value: 'JURIDICA', label: 'JURÍDICA' },
];

export const dropFretePorConta = [
  {
    value: '0 - CONTRATACAO DO FRETE POR CONTA DO REMETENTE (CIF)',
    label: '0 - CONTRATACAO DO FRETE POR CONTA DO REMETENTE (CIF)',
  },
  {
    value: '1 - CONTRATACAO DO FRETE POR CONTA DO DESTINATARIO (FOB)',
    label: '1 - CONTRATACAO DO FRETE POR CONTA DO DESTINATARIO (FOB)',
  },
  {
    value: '2 - CONTRATACAO DO FRETE POR CONTA DE TERCEIROS',
    label: '2 - CONTRATACAO DO FRETE POR CONTA DE TERCEIROS',
  },
  {
    value: '3 - TRANSPORTE PROPRIO POR CONTA DO REMETENTE',
    label: '3 - TRANSPORTE PROPRIO POR CONTA DO REMETENTE',
  },
  {
    value: '4 - TRANSPORTE PROPRIO POR CONTA DO DESTINATARIO',
    label: '4 - TRANSPORTE PROPRIO POR CONTA DO DESTINATARIO',
  },
  {
    value: '9 - SEM OCORRENCIA DE TRANSPORTE',
    label: '9 - SEM OCORRENCIA DE TRANSPORTE',
  },
];

export const dropViaTransporteFrete = [
  { value: '01 - RODOVIARIO', label: '01 - RODOVIARIO' },
  { value: '02 - AEREO', label: '02 - AEREO' },
  { value: '03 - MARITIMO', label: '03 - MARITIMO' },
  { value: '04 - FERROVIARIO', label: '04 - FERROVIARIO' },
];

export const dropNaturezaFrete = [
  {
    value: '0 - OP.VENDAS COM ONUS SUPORTADO PELO ESTAB.VENDEDOR',
    label: '0 - OP.VENDAS COM ONUS SUPORTADO PELO ESTAB.VENDEDOR',
  },
  {
    value: '1 - OP.VENDAS COM ONUS SUPORTADO PELO ADQUIRENTE',
    label: '1 - OP.VENDAS COM ONUS SUPORTADO PELO ADQUIRENTE',
  },
  {
    value: '2 - OP.COMPRAS GERADORES DE CRÉDITO',
    label: '2 - OP.COMPRAS GERADORES DE CRÉDITO',
  },
  {
    value: '3 - OP.COMPRAS NÃO GERADORES DE CRÉDITO',
    label: '3 - OP.COMPRAS NÃO GERADORES DE CRÉDITO',
  },
  {
    value: '4 - TRANSF.PRD ACABADOS ENTRE ESTAB.DA P.JURÍDICA',
    label: '4 - TRANSF.PRD ACABADOS ENTRE ESTAB.DA P.JURÍDICA',
  },
  {
    value: '5 - TRANSF.PRD EM ELABORAÇÃO ENTRE ESTAB.DA P.JURÍDICA',
    label: '5 - TRANSF.PRD EM ELABORAÇÃO ENTRE ESTAB.DA P.JURÍDICA',
  },
  { value: '9 - OUTRAS', label: '9 - OUTRAS' },
];

export const dropModelo = [
  { value: 'CFe', label: 'CFe' },
  { value: 'CTe', label: 'CTe' },
  { value: 'CUPOM', label: 'CUPOM' },
  { value: 'FATURA', label: 'FATURA' },
  { value: 'MDFe', label: 'MDFe' },
  { value: 'NF', label: 'NF' },
  { value: 'NFe', label: 'NFe' },
  { value: 'NFCe', label: 'NFCe' },
  { value: 'NFSe', label: 'NFSe' },
];

export const dropDivisao = [
  { value: 'QUANTIDADE', label: 'QUANTIDADE' },
  { value: 'VALOR', label: 'VALOR' },
];

export const dropIndPres = [
  {
    value: 0,
    label:
      '0 - Não se aplica (por exemplo, Nota Fiscal complementar ou de ajuste)',
  },
  { value: 1, label: '1 - Operação presencial' },
  { value: 2, label: '2 - Operação não presencial, pela Internet' },
  { value: 3, label: '3 - Operação não presencial, Tele atendimento' },
  { value: 4, label: '4 - NFC-e em operação com entrega a domicílio' },
  { value: 5, label: '5 - Operação presencial, fora do estabelecimento' },
  { value: 9, label: '9 - Operação não presencial, outros' },
];

export const dropStatusDocumento = [
  { value: 'CANCELADO', label: 'CANCELADO' },
  { value: 'CONCLUIDO', label: 'CONCLUÍDO' },
  { value: 'EXPEDICAO', label: 'EXPEDIÇÃO' },
  { value: 'FINALIZADO', label: 'FINALIZADO' },
  { value: 'PARALISADO', label: 'PARALISADO' },
  { value: 'PENDENTE', label: 'PENDENTE' },
];

export const dropNTributado = [
  { value: 'ISENTO', label: 'ISENTO' },
  { value: 'OUTROS', label: 'OUTROS' },
  { value: 'NENHUM', label: 'NENHUM' },
];

export const dropTipoRateio = [
  { value: 'desconto_vlr', label: 'DESCONTO' },
  { value: 'frete', label: 'FRETE' },
  { value: 'seguro', label: 'SEGURO' },
  { value: 'despac', label: 'DESPESAS ACESSÓRIAS' },
  { value: 'outros', label: 'OUTROS' },
  { value: 'eventuais', label: 'EVENTUAIS' },
  { value: 'internacionalizacao', label: 'INTERNACIONALIZAÇÃO' },
  { value: 'fretend', label: 'FRETE NÃO DESTACADO' },
  { value: 'segurond', label: 'SEGURO NÃO DESTACADO' },
  { value: 'impostond', label: 'IMPOSTOS FEDERAIS NÃO DESTACADO' },
  { value: 'comissaond', label: 'COMISSÃO NÃO DESTACADO' },
];

export const dropTipoContrato = [
  { value: 'INF', label: 'INF' },
  { value: 'SUP', label: 'SUP' },
];

export const dropReajusteContrato = [
  { value: 'SM', label: 'SM' },
  { value: 'IGPM', label: 'IGPM' },
  { value: 'SINDICATO', label: 'SINDICATO' },
];

export const dropViaTransporte = [
  { value: '01-MARITIMA', label: '01-MARITIMA' },
  { value: '02-FLUVIAL', label: '02-FLUVIAL' },
  { value: '03-LACUSTRE', label: '03-LACUSTRE' },
  { value: '04-AEREA', label: '04-AEREA' },
  { value: '05-POSTAL', label: '05-POSTAL' },
  { value: '06-FERROVIARIA', label: '06-FERROVIARIA' },
  { value: '07-RODOVIARIA', label: '07-RODOVIARIA' },
  {
    value: '08-CONDUTO / REDE TRANSMISSAO',
    label: '08-CONDUTO / REDE TRANSMISSAO',
  },
  { value: '09-MEIOS PRÓPRIOS', label: '09-MEIOS PRÓPRIOS' },
  { value: '10-ENTRADA / SAIDA LICTA', label: '10-ENTRADA / SAIDA LICTA' },
  { value: '11-COURIER', label: '11-COURIER' },
  { value: '12-HANDCARRY', label: '12-HANDCARRY' },
];

export const dropIntermedio = [
  {
    value: '1-IMPORTAÇÃO POR CONTA PRÓPRIA',
    label: '1-IMPORTAÇÃO POR CONTA PRÓPRIA',
  },
  {
    value: '2-IMPORTAÇÃO POR CONTA E ORDEM',
    label: '2-IMPORTAÇÃO POR CONTA E ORDEM',
  },
  { value: '3-IMPORTAÇÃO POR ENCOMENDA', label: '3-IMPORTAÇÃO POR ENCOMENDA' },
];

export const dropTipoEmitente = [
  { value: '1', label: 'Prestador de serviço de transporte' },
  { value: '2', label: 'Transportador de carga própria' },
  { value: '3', label: 'Transportador com emissão de CT-e' },
];

export const dropTipoTransportador = [
  { value: '1', label: 'ETC' },
  { value: '2', label: 'TAC' },
  { value: '3', label: 'CTC' },
];

export const dropSituacaoRemessa = [
  { value: 'EM ABERTO', label: 'EM ABERTO' },
  { value: 'ENCERRADO', label: 'ENCERRADO' },
];
