import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FileOpen } from '@mui/icons-material';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { openXML } from 'utils/functions';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import TableContainer from 'components/TableContainer';
import styles from '../styles';
import moment from 'moment';

const Cartas = ({ onSubmit, documento = {} }) => {
  const { openDialog } = useDialog();

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      documento_id: documento?.id,
      sequencia: documento?.DocumentoCces?.length + 1,
      carta: item?.carta || '',
    };
    const { control, watch, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);

    return (
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            A Carta de Correção é disciplinada pelo § 1º-A do art. 7º do
            Convênio S/N, de 15 de dezembro de 1970 e pode ser utilizada para
            regularização de erro ocorrido na emissão de documento fiscal, desde
            que o erro não esteja relacionado com:
          </Typography>
          <Typography variant="body1">
            I - as variáveis que determinam o valor do imposto tais como: base
            de cálculo, alíquota, diferença de preço, quantidade, valor da
            operação ou da prestação;
          </Typography>
          <Typography variant="body1">
            II - a correção de dados cadastrais que implique mudança do
            remetente ou do destinatário;
          </Typography>
          <Typography variant="body1">
            III - a data de emissão ou de saída;
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Input
            name="carta"
            control={control}
            label="Carta de Correção"
            multiline
            rows={7}
            inputProps={{ maxLength: 1000 }}
          />
          <Typography variant="body1" textAlign="end">
            {watch('carta')?.length || 0}/1000
          </Typography>
        </Grid>

        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Cces: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Cartas de Correção" style={styles?.card}>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => openDialog(<Modal />, 'Adicionar Registro')}
          >
            Adicionar
          </Button>
        </Grid>
        {!!documento?.DocumentoCces?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Sequencia</TableCell>
                    <TableCell>Data de Envio</TableCell>
                    <TableCell>Carta</TableCell>
                    <TableCell>Protocolo</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>XML de Envio</TableCell>
                    <TableCell>XML de Retorno</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documento?.DocumentoCces?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.sequencia}</TableCell>
                      <TableCell>
                        {Boolean(item?.envio) &&
                          moment(item?.envio).isValid() &&
                          moment(item?.envio).format('DD/MM/YYYY [às] HH:mm')}
                      </TableCell>
                      <TableCell>{item?.carta}</TableCell>
                      <TableCell>{item?.protocolo}</TableCell>
                      <TableCell>{item?.ccestatus}</TableCell>
                      <TableCell align="center">
                        {Boolean(item?.xmlenvio) && (
                          <IconButton
                            size="small"
                            onClick={() => openXML(item?.xmlenvio)}
                          >
                            <FileOpen />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {Boolean(item?.xmlretorno) && (
                          <IconButton
                            size="small"
                            onClick={() => openXML(item?.xmlretorno)}
                          >
                            <FileOpen />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Cartas;
