import { createContext, useState } from 'react';
import api from 'services/api';

export const ProdutosContext = createContext();

export const ProdutosProvider = ({ children }) => {
  const [postLoading, setPostLoading] = useState(false);

  const postEtiquetas = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      const res = await api.post('/Cadastros/Produto/Etiquetas', data);

      if (res?.data?.base64) {
        const byteCharacters = atob(res?.data?.base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }

      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <ProdutosContext.Provider value={{ postLoading, postEtiquetas }}>
      {children}
    </ProdutosContext.Provider>
  );
};
