const styles = {
  lineLabel: {
    flex: 1,
  },
  lineValue: {
    flex: 2,
  },
  card: {
    width: '100%',
  },
};

export default styles;
