import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  status: ['PENDENTE'],
  entidade: [],
  contrato: '',
  vldocto: '',
  dia_pagto: '',
  forma_pagto: [],
  ultimo_faturamento: {
    i: null,
    f: null,
  },
  proximo_faturamento: {
    i: null,
    f: null,
  },
};

export const ContratosContext = createContext();

export const ContratosProvider = ({ children }) => {
  const [contratos, setContratos] = useState({
    data: [],
    colunas: [],
    order: { field: 'entidade', sort: 'asc' },
    filter,
    page: 0,
    size: 10,
  });
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  const getContratos = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: contratos?.filter,
        page: contratos?.page,
        size: contratos?.size,
        order: contratos?.order,
        ...payload,
      });
      setContratos((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const PostFaturaContrato = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Comercial/Contratos/Fatura', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const PostServicoContrato = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Comercial/Contratos/Servico', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <ContratosContext.Provider
      value={{
        contratos,
        getLoading,
        postLoading,
        getContratos,
        PostFaturaContrato,
        PostServicoContrato,
      }}
    >
      {children}
    </ContratosContext.Provider>
  );
};
