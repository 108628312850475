import React from 'react';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FileOpen } from '@mui/icons-material';
import { openXML } from 'utils/functions';
import Card from 'components/Card';
import TableContainer from 'components/TableContainer';
import styles from '../styles';
import moment from 'moment';

const Transmissão = ({ documento = {} }) => {
  const chave = documento?.DocumentoNfe?.nfechaveacesso;
  return (
    <Card title="Status da Transmissão" style={styles?.card}>
      <Grid container spacing={2}>
        {!!documento?.DocumentoNfeServicos?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Data de Recebimento</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Motivo</TableCell>
                    <TableCell>XML de Envio</TableCell>
                    <TableCell>XML de Retorno</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documento?.DocumentoNfeServicos?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>
                        {Boolean(item?.dtrecebimento) &&
                          moment(item?.dtrecebimento).isValid() &&
                          moment(item?.dtrecebimento).format(
                            'DD/MM/YYYY [às] HH:mm'
                          )}
                      </TableCell>
                      <TableCell>{item?.nfe_status}</TableCell>
                      <TableCell>{item?.motivo}</TableCell>
                      <TableCell align="center">
                        {Boolean(item?.xmlenvio) && (
                          <IconButton
                            size="small"
                            onClick={() => openXML(item?.xmlenvio)}
                          >
                            <FileOpen />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {Boolean(item?.xmlretorno) && (
                          <IconButton
                            size="small"
                            onClick={() => openXML(item?.xmlretorno)}
                          >
                            <FileOpen />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        {Boolean(chave) && (
          <Grid item xs={12} textAlign="center">
            <Typography variant="caption">Chave da NF: {chave}</Typography>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Transmissão;
