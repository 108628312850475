import { createContext, useState } from 'react';
import api from 'services/api';
import moment from 'moment';

const filter = {
  like: '',
  id: '',
  status: [],
  situacao: [],
  natureza_operacao_id: [],
  dtemissao: {
    i: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    f: null,
  },
  documento: '',
  entidade: [],
  vldocto: '',
  vendedor: [],
};

const filterItens = {
  like: '',
  id: '',
  descricao: '',
  documento: '',
  referencia: '',
  dtemissao: {
    i: null,
    f: null,
  },
  quantidade: '',
  preco: '',
  kit_id: '',
};

export const VendaExternaContext = createContext();

export const VendaExternaProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [vendaExterna, setVendaExterna] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtemissao', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });
  const [vendaExternaItens, setVendaExternaItens] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtemissao', sort: 'desc' },
    filter: filterItens,
    page: 0,
    size: 10,
  });

  const getVendaExterna = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: vendaExterna?.filter,
        page: vendaExterna?.page,
        size: vendaExterna?.size,
        order: vendaExterna?.order,
        ...payload,
      });
      setVendaExterna((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getVendaExternaItens = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: vendaExternaItens?.filter,
        page: vendaExternaItens?.page,
        size: vendaExternaItens?.size,
        order: vendaExternaItens?.order,
        ...payload,
      });
      setVendaExternaItens((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <VendaExternaContext.Provider
      value={{
        vendaExterna,
        vendaExternaItens,
        getLoading,
        getVendaExterna,
        getVendaExternaItens,
      }}
    >
      {children}
    </VendaExternaContext.Provider>
  );
};
