import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DropsContext } from 'contexts/DropsContext';
import { ContratosContext } from 'contexts/ContratosContext';
import { GridContext } from 'contexts/GridContext';
import { toastWarning } from 'utils/toast';
import { useModal } from 'components/Modals';
import ReferenciaModal from 'components/Modals/ReferenciaModal';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';
import moment from 'moment';

const Contratos = () => {
  const rotina = 'Contratos';
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const {
    contratos,
    getContratos,
    getLoading,
    PostServicoContrato,
    PostFaturaContrato,
  } = useContext(ContratosContext);
  const { control, getValues, reset } = useForm({
    defaultValues: contratos?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getContratos({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar Contrato',
      icon: 'add',
      action: () => navigate('/app/Contratos/Gerar/Contrato'),
    },
    {
      name: 'Adicionar Orçamento',
      icon: 'add',
      action: () => navigate('/app/Contratos/Gerar/Orcamento'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: contratos?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: contratos?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={contratos?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Faturar',
      icon: 'post_add',
      action: (selected) => {
        if (!Boolean(selected?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        if (selected?.some((s) => s?.status !== 'PENDENTE')) {
          return toastWarning('Selecione apenas contratos em vigência');
        }
        PostFaturaContrato({ data: selected?.map((s) => s?.id), cb: loadGrid });
      },
    },
    {
      name: 'Gerar Ordem de Serviço',
      icon: 'post_add',
      action: (selected) => {
        if (!Boolean(selected?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        if (selected?.some((s) => s?.status !== 'PENDENTE')) {
          return toastWarning('Selecione apenas contratos em vigência');
        }
        openModal(
          <ReferenciaModal
            onSubmit={(dtref) => {
              const data = { dtref, documentos: selected?.map((s) => s?.id) };
              PostServicoContrato({
                data,
                cb: () => {
                  loadGrid();
                  closeModal();
                },
              });
            }}
          />
        );
      },
    },
  ];
  const rowOptions = [
    {
      name: ' Em Atraso',
      icon: 'priority_high',
      color: 'secondary.main',
      show: ({ row }) =>
        moment(row?.proximo_faturamento, 'YYYY-MM-DD').isBefore(
          moment().format('YYYY-MM-DD')
        ),
    },
    {
      name: ' À Vencer',
      icon: 'priority_high',
      color: 'secondary.main',
      show: ({ row }) =>
        moment(row?.proximo_faturamento, 'YYYY-MM-DD').isBetween(
          moment().subtract(3, 'days').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ),
    },
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.id}`),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} colunas={contratos?.colunas} />
      <Card>
        <Grid
          grid={contratos}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={contratos} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Contratos;
