import React, { useContext } from 'react';
import { DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { useModal } from 'components/Modals';
import { useForm } from 'react-hook-form';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Input from 'components/Input';
import Button from 'components/Button';

const FabrincanteModal = ({ origem_id, documento_id, callback }) => {
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({
    defaultValues: { historico: '' },
  });
  const { postLoading, postDocumentos } = useContext(DocumentosContext);

  const onSubmit = ({ historico }) => {
    const data = [
      {
        Saida: { ped_cliente: historico },
        Registros: [{ documento_id, historico }],
      },
      {
        Registros: [
          { documento_id: origem_id, historico },
          { documento_id: origem_id, status_id: 21 },
        ],
      },
    ];
    postDocumentos({ data, cb: callback });
  };

  return (
    <>
      <DialogTitle>Informar Código do Fabricante</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Input
              name="historico"
              control={control}
              label="Código do Fabricante"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)} loading={postLoading}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default FabrincanteModal;
