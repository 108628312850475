import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { toastWarning } from 'utils/toast';
import { checkInfosProduto } from 'utils/functions';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { useDialog } from 'components/Modals';
import TableContainer from 'components/TableContainer';
import VirtualDrop from 'components/VirtualDrop';
import InputMask from 'components/InputMask';
import Button from 'components/Button';
import Input from 'components/Input';

const KitModal = ({ onSubmit, vendedor_id, item }) => {
  const { closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const defaultValues = {
    produto_id: null,
    descricao: '',
    quantidade: '',
    desconto1_pc: '',
    desconto_vlr: '',
    preco: '',
    infadc: '',
    quantidade_kit: item?.quantidade || 1,
    itens: item?.itens || [],
  };
  const { control, watch, setValue, handleSubmit, resetField } = useForm({
    defaultValues,
  });

  useEffect(() => {
    const desconto_vlr =
      watch('desconto1_pc') * watch('preco') * watch('quantidade');
    setValue(
      'desconto_vlr',
      desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr
    );
  }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

  const getAdditionals = async () => {
    setLoading(true);
    const produto = await checkInfosProduto({
      produto_id: watch('produto_id'),
      disponivel: 'SIM',
      vendedor_id,
    });
    setValue('quantidade', produto?.volume || 1);
    setValue('preco', produto?.preco);
    setValue('descricao', produto?.descricao);
    setLoading(false);
  };

  const onAddItem = (values) => {
    if (!Boolean(values?.produto_id)) {
      toastWarning('Nenhum produto selecionado');
    } else {
      const subtotal =
        (watch('quantidade') || 0) * (watch('preco') || 0) +
        (watch('desconto_vlr') || 0);
      setValue('itens', [
        {
          subtotal,
          produto_id: values?.produto_id,
          descricao: values?.descricao,
          quantidade: values?.quantidade,
          desconto1_pc: values?.desconto1_pc,
          desconto_vlr: values?.desconto_vlr,
          preco: values?.preco,
          infadc: values?.infadc,
        },
        ...values?.itens,
      ]);
      resetField('produto_id');
      resetField('descricao');
      resetField('quantidade');
      resetField('desconto1_pc');
      resetField('desconto_vlr');
      resetField('preco');
      resetField('infadc');
    }
  };

  const onDeleteItem = (index) => {
    setValue(
      'itens',
      watch('itens')?.filter((_, i) => i !== index)
    );
  };

  const onConfirm = (values) => {
    if (!Boolean(values?.quantidade_kit)) {
      toastWarning('Digite a quantidade de kits');
    } else {
      const data = { quantidade: values?.quantidade_kit, itens: values?.itens };
      onSubmit(data);
      closeDialog();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          Informações do Produto
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <VirtualDrop
          name="produto_id"
          control={control}
          label="Produto"
          options={drops?.Produto}
          onValueChange={(v) => Boolean(v) && getAdditionals(v?.value)}
          refresh="Produto"
        />
      </Grid>
      <Grid item xs={6}>
        <InputMask name="quantidade" control={control} label="Quantidade" />
      </Grid>
      <Grid item xs={6}>
        <InputMask
          name="preco"
          control={control}
          label="Preço"
          disabled={user?.permissoes?.block_altera_precos}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="infadc"
          control={control}
          label="Informações Adicionais"
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSubmit(onAddItem)}
          loading={loading}
        >
          Adicionar
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          Informações do Kit
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <InputMask
          name="quantidade_kit"
          control={control}
          label="Quantidade de Kits"
        />
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell>Quantidade</TableCell>
                <TableCell>Desconto</TableCell>
                <TableCell>Preço Unitário</TableCell>
                <TableCell>Sub Total</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {watch('itens')?.map((item, index) => (
                <TableRow key={index?.toString()}>
                  <TableCell>{item?.descricao}</TableCell>
                  <TableCell>{item?.quantidade}</TableCell>
                  <TableCell>
                    {item?.desconto_vlr?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                  <TableCell>
                    {item?.preco?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                  <TableCell>
                    {item?.subtotal?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => onDeleteItem(index)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onConfirm)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};
export default KitModal;
