import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { useModal } from 'components/Modals';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';

const VendedorModal = ({ onSubmit, documento = {} }) => {
  const defaultValues = {
    documento_id: documento?.id,
    vendedor_id: documento?.DocumentoSaida?.vendedor_id || null,
  };
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues });
  const { postLoading } = useContext(DocumentosContext);
  const { drops } = useContext(DropsContext);

  const onConfirm = (Saida) => onSubmit({ Saida });

  return (
    <>
      <DialogTitle>Alterar Vendedor</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Dropdown
              name="vendedor_id"
              control={control}
              label="Vendedor"
              options={drops?.EntidadeTipo?.filter(
                (e) => e?.tipo === 'VENDEDOR' || e?.tipo === 'REPRESENTANTE'
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          loading={postLoading}
          onClick={handleSubmit(onConfirm)}
        >
          Salvar
        </Button>
        <Button variant="contained" color="secondary" onClick={closeModal}>
          Voltar
        </Button>
      </DialogActions>
    </>
  );
};
export default VendedorModal;
