import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { summarizer } from 'utils/functions';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';
import styles from './styles';
import moment from 'moment';

const Resumo = () => {
  const { id } = useParams();
  const [itens, setItens] = useState([]);
  const { getDocumento, getLoading, documento } = useContext(DocumentosContext);

  useEffect(() => {
    if (Boolean(id)) {
      getDocumento(id);
    }
  }, []);

  useEffect(() => {
    if (Boolean(id) && Boolean(documento)) {
      renderValues();
    }
  }, [documento]);

  const getVinculos = (natureza_operacao_id) => {
    switch (natureza_operacao_id) {
      case 19:
        return { natureza_retorno: 21 };
      case 22:
        return { natureza_retorno: 24 };
      case 37:
        return {
          natureza_venda: 44,
          natureza_retorno: 45,
          natureza_consignacao: 52,
          natureza_garantia: 54,
          natureza_bonificacao: 79,
        };
      case 56:
        return { natureza_retorno: 57 };
      case 62:
        return { natureza_retorno: 63 };
      case 78:
        return {
          natureza_venda: 44,
          natureza_retorno: 45,
          natureza_consignacao: 52,
          natureza_garantia: 54,
          natureza_bonificacao: 79,
        };

      default:
        return null;
    }
  };

  const renderValues = () => {
    const itens = documento?.DocumentoItems?.map((di) => {
      const naturezas = getVinculos(documento?.natureza_operacao_id);
      const quantidade = di?.quantidade || 0;
      const quantidade_retornada =
        summarizer(
          di?.Vinculos?.filter(
            (f) => f?.natureza_operacao_id === naturezas?.natureza_retorno
          ),
          'quantidade'
        ) || 0;
      const quantidade_vendida =
        summarizer(
          di?.Vinculos?.filter(
            (f) => f?.natureza_operacao_id === naturezas?.natureza_venda
          ),
          'quantidade'
        ) || 0;
      const quantidade_consignada =
        summarizer(
          di?.Vinculos?.filter(
            (f) => f?.natureza_operacao_id === naturezas?.natureza_consignacao
          ),
          'quantidade'
        ) || 0;
      const quantidade_garantia =
        summarizer(
          di?.Vinculos?.filter(
            (f) => f?.natureza_operacao_id === naturezas?.natureza_garantia
          ),
          'quantidade'
        ) || 0;
      const quantidade_bonificada =
        summarizer(
          di?.Vinculos?.filter(
            (f) => f?.natureza_operacao_id === naturezas?.natureza_bonificacao
          ),
          'quantidade'
        ) || 0;
      const saldo =
        quantidade -
        quantidade_garantia -
        quantidade_consignada -
        quantidade_retornada -
        quantidade_bonificada -
        quantidade_vendida;
      return {
        id: di?.id,
        preco: di?.preco || 0,
        Vinculos: di?.Vinculos,
        referencia: di?.referencia,
        descricao: di?.descricao || '',
        saldo,
        quantidade,
        quantidade_retornada,
        quantidade_vendida,
        quantidade_consignada,
        quantidade_garantia,
        quantidade_bonificada,
      };
    });
    setItens(itens);
  };

  const Line = ({ value, label }) => (
    <Box display="flex" alignItems="center">
      <Typography sx={styles?.lineLabel} variant="subtitle2">
        {label}:
      </Typography>
      <Typography sx={styles?.lineValue} variant="body2">
        {value}
      </Typography>
    </Box>
  );

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Resumo da Remessa" />
      <Card>
        {itens?.map((i) => (
          <Accordion key={i?.id?.toString()}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box sx={styles?.container}>
                <Typography
                  variant="h6"
                  sx={styles?.title}
                  color={i?.saldo <= 0 && 'red'}
                >
                  {i?.descricao}
                </Typography>
                <Line value={i?.referencia} label="Referência" />
                <Line value={i?.quantidade} label="Total" />
                <Line value={i?.quantidade_vendida} label="Vendas" />
                <Line value={i?.quantidade_retornada} label="Devoluções" />
                <Line value={i?.quantidade_bonificada} label="Bonificações" />
                <Line value={i?.quantidade_consignada} label="Consignações" />
                <Line value={i?.quantidade_garantia} label="Garantias" />
                <Line value={i?.saldo} label="Saldo" />
                <Line
                  value={(i?.preco || 0)?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                  label="Preço Unitário"
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {i?.Vinculos?.map((v, idx) => (
                  <Grid item xs={12} sm={6} md={4} key={idx?.toString()}>
                    <Card>
                      <Typography variant="h6" sx={styles?.title}>
                        {v?.especie}
                      </Typography>
                      <Line value={v?.cliente} label="Entidade" />
                      <Line value={v?.documento} label="Nº do Documento" />
                      <Line
                        value={
                          Boolean(v?.dtemissao) &&
                          moment(v?.dtemissao).isValid() &&
                          moment(v?.dtemissao).format('DD/MM/YYYY [às] HH:mm')
                        }
                        label="Data de Emissão"
                      />
                      <Line value={v?.quantidade} label="Quantidade" />
                      <Line
                        value={(v?.preco || 0)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                        label="Preço Unitário"
                      />
                      <Line
                        value={(
                          (v?.preco || 0) * (v?.quantidade || 0)
                        )?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                        label="Preço Total"
                      />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Card>
    </Container>
  );
};

export default Resumo;
