import React, { useContext } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { VendaContext } from 'contexts/VendaContext';
import { useModal } from 'components/Modals';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import moment from 'moment';

const TransmissãoModal = ({ selected = [], callback }) => {
  const { closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { postDocumentos, postLoading } = useContext(DocumentosContext);
  const { contas } = useContext(VendaContext);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      serie_id: null,
      dtsaida: moment().format('YYYY-MM-DD HH:mm:ss'),
    },
  });

  const onSubmit = (values) => {
    const data = selected?.map((s) => ({
      Documento: {
        id: s?.id,
        conferido: 'SIM',
      },
      Saida: {
        documento_id: s?.id,
        ...values,
      },
      Transmitir: true,
    }));

    postDocumentos({
      data,
      cb: () => {
        closeModal();
        if (callback) {
          callback();
        }
      },
    });
  };

  return (
    <>
      <DialogTitle>Transmitir / Registrar Notas</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" textAlign="center" color="secondary">
              Atenção! Somente notas sem modelo serão transmitidas.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="serie_id"
              control={control}
              label="Série"
              options={drops?.Serie?.filter(
                (f) =>
                  !Boolean(f?.cxconta_id) ||
                  f?.cxconta_id === contas?.cxconta_id
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="dtsaida"
              control={control}
              label="Data de Saída"
              type="datetime"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)} loading={postLoading}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default TransmissãoModal;
