import { createContext, useState } from 'react';
import api from 'services/api';

export const VendaContext = createContext();

export const VendaProvider = ({ children }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [contas, setContas] = useState({});
  const [lancamentos, setLancamentos] = useState([]);

  const getContas = async () => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Comercial/Venda');
      setContas(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getLancamentos = async (params) => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Comercial/Venda/Lancamentos', {
        params,
      });
      setLancamentos(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postVenda = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      const { data: res } = await api.post('/Comercial/Venda/Caixa', data);
      if (cb) {
        cb();
      }
      if (res?.url) {
        window.open(res?.url);
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postLancamento = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Comercial/Venda/Lancamentos', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteUltimaVenda = async (params) => {
    try {
      setDeleteLoading(true);
      await api.delete('/Comercial/Venda/Cancelar', { params });
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <VendaContext.Provider
      value={{
        postVenda,
        postLoading,
        getContas,
        getLoading,
        contas,
        postLancamento,
        getLancamentos,
        lancamentos,
        deleteUltimaVenda,
        deleteLoading,
      }}
    >
      {children}
    </VendaContext.Provider>
  );
};
