import React from 'react';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { findOnArray } from 'utils/functions';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import TableContainer from 'components/TableContainer';
import moment from 'moment';

const Pagamento = ({
  tab,
  drops,
  watch,
  control,
  setValue,
  onAddPagto,
  handleSubmit,
  onChangePagto,
  onDeletePagto,
}) => {
  const display = tab === 2 ? 'flex' : 'none';
  const modalidade = findOnArray(
    watch('forma_pagto_id'),
    drops?.FormaDePagamento,
    'modalidade'
  );
  const isCartao =
    modalidade === '03-CARTAO DE CREDITO' ||
    modalidade === '04-CARTAO DE DEBITO';
  return (
    <Grid container spacing={2} sx={{ display }}>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="forma_pagto_id"
          control={control}
          label="Forma de Pagamento"
          onValueChange={() => {
            setValue('autenticacao', '');
            setValue('condicao_pagamento_id', null);
          }}
          options={drops?.FormaDePagamento?.filter(
            (f) =>
              f?.caixa === 'SIM' &&
              f?.modulo !== 'COMPRAS' &&
              f?.modulo !== 'CHEQUES'
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="condicao_pagamento_id"
          control={control}
          label="Condição de Pagamento"
          options={findOnArray(
            watch('forma_pagto_id'),
            drops?.FormaDePagamento,
            'condicoes'
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask name="valor" control={control} label="Valor" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          name="autenticacao"
          control={control}
          label="SKU (Autenticação)"
          disabled={!isCartao}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onAddPagto)}
        >
          ADICIONAR PAGAMENTO
        </Button>
      </Grid>
      {Boolean(watch('pagamento')?.length) && (
        <Grid item xs={12}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Parcela</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Forma de Pagamento</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {watch('pagamento')?.map((item, index) => (
                  <TableRow key={index?.toString()}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {Boolean(item?.dtvenc) &&
                        moment(item?.dtvenc).isValid() &&
                        moment(item?.dtvenc).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      {item?.valor?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </TableCell>
                    <TableCell>
                      {findOnArray(
                        item?.forma_pagto_id,
                        drops?.FormaDePagamento,
                        'label'
                      )}
                    </TableCell>
                    <TableCell>{item?.autenticacao}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        onClick={() => onChangePagto({ item, index })}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => onDeletePagto(index)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
};

export default Pagamento;
