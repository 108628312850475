const styles = {
  container: {
    flexGrow: 1,
  },
  title: {
    mb: '1em',
  },
  lineLabel: {
    flex: 1,
  },
  lineValue: {
    flex: 2,
  },
};

export default styles;
