import React from 'react';
import { Grid } from '@mui/material';
import Input from 'components/Input';
import InputMask from 'components/InputMask';

const Filter = ({ grid, control }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('descricao') && (
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={12}>
          <Input
            name="documento"
            control={control}
            label="Nº do Documento"
            type="number"
          />
        </Grid>
      )}
      {checkDisplay('referencia') && (
        <Grid item xs={12}>
          <Input name="referencia" control={control} label="Referência" />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.i"
            control={control}
            label="Data de Emissão Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.f"
            control={control}
            label="Emissão Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('quantidade') && (
        <Grid item xs={12}>
          <InputMask name="quantidade" control={control} label="Quantidade" />
        </Grid>
      )}
      {checkDisplay('preco') && (
        <Grid item xs={12}>
          <InputMask name="preco" control={control} label="Preço Unitário" />
        </Grid>
      )}
      {checkDisplay('kit_id') && (
        <Grid item xs={12}>
          <InputMask name="kit_id" control={control} label="Código do Kit" />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
