import React, { useState, useEffect } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { AllInbox, QrCode } from '@mui/icons-material';
import { checkInfosProduto } from 'utils/functions';
import Input from 'components/Input';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';
import Blank from 'assets/blank.png';
import styles from '../styles';

const Produto = ({
  tab,
  user,
  drops,
  itens,
  watch,
  control,
  setValue,
  getValues,
  onAddItem,
  resetField,
  handleSubmit,
}) => {
  let timer;
  const waitTime = 1000;
  const display = tab === 0 ? 'flex' : 'none';
  const [useBarCode, setUserBarCode] = useState(false);
  const [additionals, setAdditionals] = useState(null);
  const [loading, setLoading] = useState(false);

  const getSubTotal = () =>
    (watch('quantidade') || 0) * (watch('preco') || 0) +
    (watch('desconto_vlr') || 0);

  const getAdditionals = async ({ submit }) => {
    setLoading(true);
    const produto = await checkInfosProduto({
      produto_id: watch('produto_id'),
      vendedor_id: watch('vendedor_id'),
      tabpreco_id: watch('tabpreco_id'),
      filial_id: user?.filial_id,
      disponivel: 'SIM',
    });
    setAdditionals(produto);
    setValue('preco', produto?.preco);
    setValue('desconto1_pc', produto?.desconto1_pc);
    setValue('quantidade', produto?.quantidade || 1);
    if (submit) {
      onAdd({ ...produto, ...getValues() });
    }
    setLoading(false);
  };

  const onAdd = (values) => {
    const subtotal = getSubTotal();
    onAddItem({ ...values, subtotal });
  };

  useEffect(() => {
    if (Boolean(watch('produto_id'))) {
      getAdditionals({
        submit: Boolean(watch('cdbarra')) && Boolean(watch('produto_id')),
      });
    } else {
      resetField('quantidade');
      resetField('desconto1_pc');
      resetField('desconto_vlr');
      resetField('preco');
      resetField('infadc');
      setAdditionals(null);
    }
  }, [watch('produto_id')]);

  useEffect(() => {
    const desconto_vlr =
      watch('desconto1_pc') * watch('preco') * watch('quantidade');
    setValue(
      'desconto_vlr',
      desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr
    );
  }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

  return (
    <Grid container spacing={2} sx={{ display }}>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="tabpreco_id"
          control={control}
          label="Tabela de Preço"
          options={drops?.TabelaPreco}
          disabled={Boolean(itens?.length)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="vendedor_id"
          control={control}
          label="Vendedor"
          options={drops?.EntidadeTipo?.filter((f) => f?.tipo === 'VENDEDOR')}
          disabled={Boolean(itens?.length)}
        />
      </Grid>
      <Grid item xs={10}>
        {Boolean(useBarCode) ? (
          <Input
            name="cdbarra"
            control={control}
            label="Código de Barras"
            onKeyUp={(e) => {
              clearTimeout(timer);
              timer = setTimeout(() => {
                const value = e?.target?.value;
                if (Boolean(value)) {
                  const produto = drops?.Produto.find(
                    (f) => f?.cdbarra == e?.target?.value
                  );
                  setValue('produto_id', produto?.value || null);
                }
              }, waitTime);
            }}
          />
        ) : (
          <VirtualDrop
            name="produto_id"
            control={control}
            label="Produto"
            options={drops?.Produto}
            refresh="Produto"
          />
        )}
      </Grid>
      <Grid item xs={2} textAlign="center">
        <IconButton size="small" onClick={() => setUserBarCode(!useBarCode)}>
          {Boolean(useBarCode) ? (
            <AllInbox color="primary" />
          ) : (
            <QrCode color="primary" />
          )}
        </IconButton>
      </Grid>
      <Grid item xs={4}>
        <InputMask name="quantidade" control={control} label="Quantidade" />
      </Grid>
      <Grid item xs={4}>
        <InputMask name="desconto1_pc" control={control} label="% Desconto" />
      </Grid>
      <Grid item xs={4}>
        <InputMask
          name="preco"
          control={control}
          label="Preço"
          disabled={user?.permissoes?.block_altera_precos}
        />
      </Grid>
      <Grid item xs={12}>
        <Input name="infadc" control={control} label="Informações Adicionais" />
      </Grid>
      <Grid item xs={12} sm={6} {...styles?.detailsProps}>
        <Box
          component="img"
          sx={styles?.image}
          src={additionals?.imagem || Blank}
        />
      </Grid>
      {Boolean(loading) ? (
        <Grid item xs={12} sm={6} {...styles?.detailsProps}>
          <Loader />
        </Grid>
      ) : (
        <Grid item xs={12} sm={6} {...styles?.textDetailsProps}>
          <Typography variant="h6" color="primary">
            {additionals?.descricao}
          </Typography>
          <Typography variant="body1">
            Referência: {additionals?.referencia || '-'}
          </Typography>
          <Typography variant="body1">
            Saldo: {additionals?.saldo || '0'}{' '}
            {additionals?.UnidadeMedida?.descricao || ''}
          </Typography>
          <Typography variant="body1">
            Localização: {additionals?.localizador || '-'}
          </Typography>
          <Typography variant="h6" align="right" color="primary">
            {getSubTotal()?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          loading={loading}
          onClick={handleSubmit((values) =>
            onAdd({ ...additionals, ...values })
          )}
        >
          ADICIONAR
        </Button>
      </Grid>
    </Grid>
  );
};

export default Produto;
