import React, { useContext, useState } from 'react';
import {
  Box,
  Grid,
  InputLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { findOnArray, summarizer } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import TableContainer from 'components/TableContainer';
import Card from 'components/Card';
import Button from 'components/Button';
import moment from 'moment';

const ResumoModal = ({ values, onReset, vendedor_id }) => {
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postLoading, postDocumentos } = useContext(DocumentosContext);
  const [type, setType] = useState(1);
  const total_recebido = summarizer(values?.pagamento, 'valor') || 0;
  const cliente = findOnArray(values?.cadastro_id, drops?.Entidade);
  const split =
    Boolean(cliente?.divisao) && Boolean(cliente?.percentual) && type === 3;

  const onSubmit = () => {
    let especie_id = null;
    let serie_id = null;
    let modelofiscal_id = null;
    let itens_1 = values?.itens || [];
    let itens_2 = [];
    const size = values?.pagamento?.length;
    const tipo = cliente.divisao;
    const percentual = cliente.percentual / 100;
    switch (type) {
      //FATURA
      case 0:
        especie_id = 25;
        modelofiscal_id = 99;
        break;
      //Cupom
      case 1:
        especie_id = 43;
        modelofiscal_id = 3;
        break;
      //CF-e
      case 2:
        especie_id = 26;
        modelofiscal_id = 33;
        break;
      //NF-e
      case 3:
        especie_id = 10;
        modelofiscal_id = 31;
        break;
      //NFC-e
      case 4:
        especie_id = 50;
        modelofiscal_id = 37;
        break;

      default:
        break;
    }
    serie_id =
      drops?.Serie?.find((f) => f?.modelofiscal_id === modelofiscal_id)
        ?.value || null;

    if (split && tipo === 'VALOR') {
      itens_1 = values?.itens?.map((it) => {
        const item = { ...it };
        item['preco'] = parseFloat((item?.preco * percentual)?.toFixed(2));
        item['desconto_vlr'] = parseFloat(
          (item?.desconto_vlr * percentual)?.toFixed(2)
        );
        item['subtotal'] =
          (item?.preco || 0) * (item?.quantidade || 0) +
          (item?.desconto_vlr || 0);
        return item;
      });
      itens_2 = values?.itens?.map((it) => {
        const item = { ...it };
        item['preco'] = parseFloat(
          (
            item?.preco * item?.quantidade -
            item?.quantidade *
              parseFloat((item?.preco * percentual)?.toFixed(2))
          ).toFixed(2)
        );
        item['desconto_vlr'] = parseFloat(
          (
            item.desconto_vlr -
            parseFloat((item?.desconto_vlr * percentual)?.toFixed(2))
          )?.toFixed(2)
        );
        item['quantidade'] = 0;
        item['subtotal'] =
          (item?.preco || 0) * (item?.quantidade || 1) +
          (item?.desconto_vlr || 0);
        return item;
      });
    } else if (split && tipo === 'QUANTIDADE') {
      itens_1 = values?.itens
        ?.map((it) => {
          const item = { ...it };
          let sqte = item?.quantidade_max;
          item['quantidade'] = Math.ceil(item?.quantidade * percentual);
          item['desconto_vlr'] = parseFloat(
            ((item?.desconto_vlr / sqte) * item?.quantidade)?.toFixed(2)
          );
          item['subtotal'] =
            (item?.preco || 0) * (item?.quantidade || 0) +
            (item?.desconto_vlr || 0);
          return item;
        })
        ?.filter((f) => Boolean(f?.quantidade));
      itens_2 = values?.itens
        ?.map((it) => {
          const item = { ...it };
          let sqte = item?.quantidade_max;
          item['quantidade'] =
            item?.quantidade - Math.ceil(item?.quantidade * percentual);
          item['desconto_vlr'] = parseFloat(
            (
              item.desconto_vlr -
              parseFloat(
                ((item?.desconto_vlr / sqte) * item?.quantidade)?.toFixed(2)
              )
            )?.toFixed(2)
          );
          item['subtotal'] =
            (item?.preco || 0) * (item?.quantidade || 0) +
            (item?.desconto_vlr || 0);
          return item;
        })
        ?.filter((f) => Boolean(f?.quantidade));
    }

    let documento = {
      Documento: {
        especie_id,
        natureza_operacao_id: 44,
        users_id: user?.id,
        filial_id: user?.filial_id,
        cadastro_id: values?.cadastro_id,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      Saida: {
        serie_id,
        condicao_pagamento_id: values?.condicao_pagamento_id,
        vendedor_id: vendedor_id || user?.entidade_id,
        dtsaida: moment().format('YYYY-MM-DD HH:mm:ss'),
        documento:
          values?.pessoa === 'FISICA'
            ? values?.cpf
            : values?.pessoa === 'JURIDICA'
            ? values?.cnpj
            : null,
      },
    };

    const data = [];
    const nota = {
      ...documento,
      Itens: itens_1?.map((i, index) => ({
        sequencia: index + 1,
        produto_id: i?.produto_id,
        descricao: i?.descricao,
        quantidade: i?.quantidade,
        preco: i?.preco,
        precobase_id: i?.precobase_id,
        desconto1_pc: i?.desconto1_pc,
        desconto_vlr: i?.desconto_vlr,
        Vinculos: [{ documento_item_id_vinculo: i?.id }],
      })),
      Financeiros: values?.pagamento?.map((p, i) => {
        const total = summarizer(values?.itens, 'subtotal');
        const total_frac = summarizer(itens_1, 'subtotal');
        let valor = parseFloat(((p?.valor / total) * total_frac)?.toFixed(2));
        if (size === i + 1) {
          valor = Number(
            (
              total_frac -
              i *
                parseFloat(
                  ((values?.pagamento[0]?.valor / total) * total_frac)?.toFixed(
                    2
                  )
                )
            )?.toFixed(2)
          );
        }
        return { ...p, valor, parcela: i + 1 };
      }),
      Origens: [...new Set(itens_1?.map((i) => i?.documento_id))]?.map((n) => ({
        documento_id: n,
      })),
    };
    data?.push(nota);
    if (split) {
      const fatura = {
        ...documento,
        Documento: { ...documento?.Documento, especie_id: 25 },
        Saida: {
          ...documento?.Saida,
          serie_id:
            drops?.Serie?.find((f) => f?.modelofiscal_id === 99)?.value || null,
        },
        Itens: itens_2?.map((i, index) => ({
          sequencia: index + 1,
          produto_id: i?.produto_id,
          descricao: i?.descricao,
          quantidade: i?.quantidade,
          preco: i?.preco,
          precobase_id: i?.precobase_id,
          desconto1_pc: i?.desconto1_pc,
          desconto_vlr: i?.desconto_vlr,
          Vinculos: [{ documento_item_id_vinculo: i?.id }],
        })),
        Financeiros: values?.pagamento?.map((p, i) => {
          const total = summarizer(values?.itens, 'subtotal');
          const total_frac = summarizer(itens_2, 'subtotal');
          let valor = parseFloat(((p?.valor / total) * total_frac)?.toFixed(2));
          if (size === i + 1) {
            valor = Number(
              (
                total_frac -
                i *
                  parseFloat(
                    (
                      (values?.pagamento[0]?.valor / total) *
                      total_frac
                    )?.toFixed(2)
                  )
              )?.toFixed(2)
            );
          }
          return { ...p, valor, parcela: i + 1 };
        }),
        Origens: [...new Set(itens_2?.map((i) => i?.documento_id))]?.map(
          (n) => ({ documento_id: n })
        ),
      };
      data?.push(fatura);
    }
    postDocumentos({ data, cb: onReset });
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography variant="h6">Dados</Typography>
        <Typography variant="body1">
          Data: {moment().format('DD/MM/YYYY [às] HH:mm')}
        </Typography>
      </Grid>
      {(Boolean(values?.cadastro_id) ||
        Boolean(values?.cpf) ||
        Boolean(values?.cnpj)) && (
        <Grid item xs={12}>
          <Typography variant="h6">Cliente</Typography>
          {Boolean(cliente?.label) && (
            <Typography variant="body1">Nome: {cliente?.label}</Typography>
          )}
          {Boolean(values?.cpf) && (
            <Typography variant="body1">CPF: {values?.cpf}</Typography>
          )}
          {Boolean(values?.cnpj) && (
            <Typography variant="body1">CNPJ: {values?.cnpj}</Typography>
          )}
          {Boolean(cliente?.Cidade) && (
            <Typography variant="body1">Cidade: {cliente?.Cidade}</Typography>
          )}
          {Boolean(cliente?.percentual) && Boolean(cliente?.divisao) && (
            <Typography variant="body1" color="secondary">
              Divisão: {cliente?.percentual}% ({cliente?.divisao})
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h6">Itens</Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Produto</TableCell>
                <TableCell>Quantidade</TableCell>
                <TableCell>Desconto</TableCell>
                <TableCell>Preço Unitário</TableCell>
                <TableCell>Sub total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values?.itens?.map((item, index) => (
                <TableRow key={index?.toString()}>
                  <TableCell>{item?.descricao}</TableCell>
                  <TableCell>{item?.quantidade}</TableCell>
                  <TableCell>
                    {item?.desconto_vlr?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                  <TableCell>
                    {item?.preco?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                  <TableCell>
                    {item?.subtotal?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Pagamento</Typography>
        {Boolean(values?.pagamento?.length) && (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Parcela</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Forma de Pagamento</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values?.pagamento?.map((item, index) => (
                  <TableRow key={index?.toString()}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {Boolean(item?.dtvenc) &&
                        moment(item?.dtvenc).isValid() &&
                        moment(item?.dtvenc).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      {item?.valor?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </TableCell>
                    <TableCell>
                      {findOnArray(
                        item?.forma_pagto_id,
                        drops?.FormaDePagamento,
                        'label'
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card>
              <Typography variant="h6" color="primary" align="center">
                Total:{' '}
                {summarizer(values?.itens, 'subtotal')?.toLocaleString(
                  'pt-br',
                  {
                    style: 'currency',
                    currency: 'BRL',
                  }
                )}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <Typography variant="h6" color="primary" align="center">
                Total Recebido:{' '}
                {total_recebido?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Emissão</Typography>
        <Box display="flex" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <Radio
              name="fatura"
              id="fatura"
              color="primary"
              size="small"
              checked={type === 0}
              onChange={() => setType(0)}
            />
            <Tooltip title="Emitir fatura" placement="top">
              <InputLabel htmlFor="fatura">Fatura</InputLabel>
            </Tooltip>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <Radio
              name="cupom"
              id="cupom"
              color="primary"
              size="small"
              checked={type === 1}
              onChange={() => setType(1)}
            />
            <Tooltip title="Emitir cupom" placement="top">
              <InputLabel htmlFor="cupom">Cupom</InputLabel>
            </Tooltip>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <Radio
              name="cfe"
              id="cfe"
              color="primary"
              size="small"
              checked={type === 2}
              onChange={() => setType(2)}
            />
            <Tooltip
              title="Emitir cupom fiscal eletrônico / SAT"
              placement="top"
            >
              <InputLabel htmlFor="cfe">CF-e</InputLabel>
            </Tooltip>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <Radio
              name="nfe"
              id="nfe"
              color="primary"
              size="small"
              checked={type === 3}
              onChange={() => setType(3)}
            />
            <Tooltip title="Emitir nota fiscal eletrônica" placement="top">
              <InputLabel htmlFor="nfe">NF-e</InputLabel>
            </Tooltip>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <Radio
              name="nfce"
              id="nfce"
              color="primary"
              size="small"
              checked={type === 4}
              onChange={() => setType(4)}
            />
            <Tooltip
              title="Emitir nota fiscal de consumidor eletrônica"
              placement="top"
            >
              <InputLabel htmlFor="nfce">NFC-e</InputLabel>
            </Tooltip>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          loading={postLoading}
        >
          Finalizar
        </Button>
      </Grid>
    </Grid>
  );
};
export default ResumoModal;
